import { useState, useEffect } from "react";
import ErrorBox from "../utils/ErrorBox";
import PageLoader from "../utils/PageLoader";
import "../MainContent/CCollegeService/CCollegeService.css";
import OrganigramService from "../../services/OrganigramService";

const Organigram = () => {
  const [organigram, setOrganigram] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

useEffect(() => {
  loadServiceItem();      
}, []);

const loadServiceItem = () => {
  setLoaded(false);
  OrganigramService.loadOrganigram()
    .then((response) => {       
      setOrganigram(response.data);       
      setLoaded(true);
    })
    .catch((error) => {
      setError(true);
      setLoaded(true);
    });
};
if (!loaded) {
  return (
      <div style={{ minHeight: "250px" }}>
        <PageLoader />
      </div>
    );
} else {
  if (error) {
    return <ErrorBox text="Failed to load the service data" />;
  } else {
    return (
      <div style={{ minHeight: "250px" }} >
      {organigram.content ? (
        <div
          className="container my-5"
          dangerouslySetInnerHTML={{ __html: organigram.content }}
        />
      ) : (
        <div className="mt-5">
          <img src="/images/Organogram.jpg" alt="" />
        </div>
      )}
     </div>
    );
  };
  }
}
 
export default Organigram;
