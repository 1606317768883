import React, { useEffect, useState } from "react";
import "./Iqac.css";
import LineBottom from "../../utils/LineBottom";
import IqacService from "../../../services/IqacService";
import PageLoader from "../../utils/PageLoader";
import ErrorBox from "../../utils/ErrorBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";

const Iqac = () => {
  let { slug } = useParams();
  const [showContent, setShowContent] = useState(true);
  const [iqacLinks, setIqacLinks] = useState([]);
  const [linkLoader, setLinksLoader] = useState(false);

  const [iqacContent, setIqacContent] = useState({});
  const [iqacUploads, setIqacUploads] = useState([]);
  const [contentLoader, setContentLoader] = useState(false);

  const [iqacError, setIqacError] = useState("");
  const [contentError, setContentError] = useState("");

  const getIQACLinks = () => {
    setLinksLoader(true);
    IqacService.getIQACLinks()
      .then((response) => {
        if (response.data && response.data.length) {
          const data = response.data.map((item, index) => {
            const itemCopy = item;
            if (slug) {
              if (itemCopy.slug === slug) {
                itemCopy.activeItem = true;
                if (itemCopy.isSection) getIQACContentBySlug(slug);
                else getIQACUploadsBySlug(slug);
              }
            } else {
              if (index === 0) {
                itemCopy.activeItem = true;
                if (itemCopy.isSection) getIQACContent(itemCopy.id);
                else getIQACUploads(itemCopy.id);
              } else itemCopy.activeItem = false;
            }
            return itemCopy;
          });
          setIqacLinks(data);
        }
        setLinksLoader(false);
      })
      .catch((error) => {
        setIqacError("Failed to load IQAC information");
        setLinksLoader(false);
      });
  };

  const getIQACContent = (Id) => {
    setContentLoader(true);
    IqacService.getIQACContent(Id)
      .then((response) => {
        setIqacContent(response.data);
        setContentLoader(false);
      })
      .catch((error) => {
        setContentError("Failed to load IQAC information");
        contentLoader(false);
      });
  };

  const getIQACUploads = (Id) => {
    setContentLoader(true);
    IqacService.getIQACUploads(Id)
      .then((response) => {
        setIqacUploads(response.data);
        setContentLoader(false);
      })
      .catch((error) => {
        setContentError("Failed to load IQAC information");
        setContentLoader(false);
      });
  };

  const getIQACContentBySlug = (slug) => {
    setContentLoader(true);
    setIqacUploads([]);
    IqacService.getIQACContentbySlug(slug)
      .then((response) => {
        setIqacContent(response.data);
        setShowContent(true);
        setContentLoader(false);
      })
      .catch((error) => {
        setContentError("Failed to load IQAC information");
        contentLoader(false);
      });
  };

  const getIQACUploadsBySlug = (slug) => {
    setContentLoader(true);
    setIqacContent({});
    IqacService.getIQACUploadsBySlug(slug)
      .then((response) => {
        setIqacUploads(response.data);
        setShowContent(false);
        setContentLoader(false);
      })
      .catch((error) => {
        setContentError("Failed to load IQAC information");
        setContentLoader(false);
      });
  };

  useEffect(() => {
    getIQACLinks();
  }, [slug]);

  const handleLinkClick = (item, indexValue) => {
    // change active link
    const data = iqacLinks.map((item, index) => {
      const itemCopy = item;
      if (index === indexValue) item.activeItem = true;
      else item.activeItem = false;
      return itemCopy;
    });
    setIqacLinks(data);
    // end
    if (item.isSection) {
      setShowContent(true);
      setIqacUploads([]);
      getIQACContent(item.id);
    } else {
      setShowContent(false);
      setIqacContent({});
      getIQACUploads(item.id);
    }
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };
  const renderContent = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            iqacContent && iqacContent.content
              ? iqacContent.content
              : "No information found",
        }}
      ></div>
    );
  };
  const renderUploads = () => {
    return (
      <ul className="nirfPage-list p-0">
        {iqacUploads && iqacUploads.length
          ? iqacUploads.map((notice) => {
              return (
                <li key={notice.id} className="nirfPage-list-item pl-0">
                  <div className="nirfPage-iconnirfWrapper d-flex">
                    <button className="nirfPage-iqacIcon ml-0">
                      <FontAwesomeIcon icon={faArrowCircleRight} />
                    </button>
                    <div className="nirfPage-link">
                      <a
                        target="_blank"
                        className="nirfPage-link-item"
                        href={
                          process.env.REACT_APP_HOST_URL +
                          "uploads/" +
                          notice.fileLink
                        }
                      >
                        <span className="nirfPage-nirftitle px-2">
                          {notice.title}
                        </span>
                      </a>
                    </div>
                  </div>
                  <a
                    target="_blank"
                    className="nirfPage-download-btn"
                    href={
                      process.env.REACT_APP_HOST_URL +
                      "uploads/" +
                      notice.fileLink
                    }
                  >
                    <span className="nirfPage-downloadtext">Download</span>
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </li>
              );
            })
          : "No information found"}
      </ul>
    );
  };
  return (
    <div className="container my-5">
      <LineBottom span="IQAC" text="Section" />

      {linkLoader ? (
        <PageLoader />
      ) : iqacError ? (
        <ErrorBox text={iqacError} />
      ) : iqacLinks && iqacLinks.length ? (
        <div className="iqac-wrapper">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3>Internal Quality Assurance Cell (IQAC)</h3>
              <p style={{ marginBottom: "10px" }}>
                {" "}
                Excellence Through Quality Education...
              </p>
            </div>
          </div>
          <div className="iqac-content-wrapper">
            <div className="iqac-content container">
              <div className="row">
                <div className="col-lg-1 col-3" style={{ padding: "0" }}>
                  <div className="list-group-iqac">
                    {iqacLinks.map((item, index) => {
                      return (
                        <Link key={item.id} to={"/iqac-downloads/" + item.slug}>
                          <div
                            //  onClick={() => handleLinkClick(item, index)}
                            className={`list-group-item ${
                              item.activeItem ? "active-item" : ""
                            }`}
                          >
                            <h3 className="link-head">
                              <i
                                className={`fa ${
                                  item.icon ? item.icon : "fa-file"
                                }`}
                              ></i>
                              <p>{item.name}</p>
                            </h3>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
                <div style={{ overflow: "auto" }} className="col-lg-11 col-9">
                  <div className="main-iqac-content">
                    {contentLoader ? (
                      <PageLoader />
                    ) : contentError ? (
                      <ErrorBox />
                    ) : showContent ? (
                      renderContent()
                    ) : (
                      renderUploads()
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="galleryContent-gallery">
          <span className="badge badge-warning">No information found.</span>
        </div>
      )}
    </div>
  );
};

export default Iqac;
