import { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import PublicService from "../../../../services/PublicService";
import PageLoader from "../../../utils/PageLoader";
import ErrorBox from "../../../utils/ErrorBox";
import Slider from "react-slick";
const StudentSayBar = () => {
  const [studentsSay, setStudentsSay] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const loadStudentsSay = () => {
    setLoader(true);
    PublicService.loadStudentSay()
      .then((response) => {
        setStudentsSay(response.data);
        setLoader(false);
      })
      .catch((error) => {
        setError("Failed to load students say");
        setLoader(false);
      });
  };

  useEffect(() => {
    loadStudentsSay();
  }, []);

  return (
    <div className="studentSayWrapper">
      <div className="container">
        <div className="wrapper">
          <h2>Student says</h2>

          <Slider {...settings}>
            {studentsSay.map((review, index) => (
              <div key={index}>
                <h5 dangerouslySetInnerHTML={{ __html: review.comment }}></h5>
                <p className="student-name" style={{ fontStyle: "italic" }}>
                  {review.studentName},{" "}
                  {review.courseName + " (" + review.semester + " sem)"}
                </p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default StudentSayBar;
