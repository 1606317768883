import http from "./HttpService";
const loadDynamicPageItem = async (slug) => {
  try {
    const response = await http.get(`dynamicpage/get-dynamic-page-byslug?slug=${slug}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const loadDynamicLinks = async () => {
  try {
    const response = await http.get("dynamicpage/get-dynamic-links");
    return response.data;
  } catch (error) {
    throw error;
  }
};
const DynamicPageService = {
  loadDynamicPageItem,
  loadDynamicLinks
  };
  export default DynamicPageService;
  