import React from "react";
import { getPageNumbers } from "../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faAngleDoubleLeft,
} from "@fortawesome/free-solid-svg-icons";

const Pagination = ({ firstPage, lastPage, currentPage, handlePageChange }) => {
  const pageNumbers = getPageNumbers(firstPage, lastPage, currentPage);
  return (
    <div className="d-flex justify-content-center mt-md-5 mt-3">
      <div className="pagination-container">
        <ul className="page-list list-unstyled">
          {currentPage !== 1 && (
            <li
              onClick={() => handlePageChange(currentPage - 1)}
              className="page-item"
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </li>
          )}
          {pageNumbers.map((pageNumber, index) =>
            pageNumber !== 0 ? (
              <li
                onClick={() => handlePageChange(pageNumber)}
                className={
                  pageNumber === currentPage ? "page-item active" : "page-item"
                }
                key={index}
              >
                {pageNumber}
              </li>
            ) : (
              <span key={index} className="pagination-dots mx-1">
                ...
              </span>
            )
          )}
          {currentPage !== lastPage && (
            <li
              onClick={() => handlePageChange(currentPage + 1)}
              className="page-item"
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
