import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PublicService from "../../../../services/PublicService";
import PageLoader from "../../../utils/PageLoader";
import ErrorBox from "../../../utils/ErrorBox";
import LineBottom from "../../../utils/LineBottom";

const PrincipalMessage = () => {
  const [instituteDetails, setInstituteDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const getInstituteDetails = () => {
    setLoader(true);
    PublicService.getInstituteDetails()
      .then((response) => {
        setInstituteDetails(response.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setError("Failed to load data");
      });
  };

  useEffect(() => {
    window.scroll(0, 0);
    getInstituteDetails();
  }, []);

  return (
    <div className="pm">
      <div className="banner_image">
        <Image src="/images/banner.jpg" />
        <div className="banner-description">
          <h4>Principal Message</h4>
          <div className="mt-1 banner-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Principal Message</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      {loader ? (
        <PageLoader />
      ) : error ? (
        <ErrorBox />
      ) : (
        <div className="container">
          <div className="row d-flex my-4">
            <div className="col-md-4 col-lg-3 col-sm-4">
              <img
                src={
                  process.env.REACT_APP_HOST_URL +
                  "uploads/" +
                  instituteDetails.principalPhoto
                }
                className="img-fullwidth"
                alt="Professor (Dr.) Khursheed Ahmad"
              />
            </div>
            <div className="col-md-8 col-lg-9 col-sm-8 mt-3 mt-md-0">
              <div className="heading">
                <LineBottom span="Principal's" text="Message" />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: instituteDetails.principalMessage,
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrincipalMessage;
