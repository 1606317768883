import { useState, useEffect } from "react";
import ImageBanner from "../../utils/ImageBanner";
import LineBottom from "../../utils/LineBottom";
import Downloads from "../Home/Downloads/Downloads";
import UpcomingEvents from "../Home/LinksStudentSay/UpcomingEvents";
import News from "../Home/NotifyNewsSection/News";
import Notification from "../Home/NotifyNewsSection/Notification";
import "./Department.css";
import DepartmentLinks from "./DepartmentLinks";
import DepartmentSections from "./DepartmentSections";
import { Link, useParams } from "react-router-dom";
import PublicService from "../../../services/PublicService";
import PageLoader from "../../utils/PageLoader";
import StaffMembers from "./StaffMembers";
import HodMessage from "./HodMessage";
import NewsBar from "../Home/NotifyNewsSection/NewsBar";

const DSW = () => {
  let { slug } = useParams();
  const [showHodMessage, setShowHodMessage] = useState(false);
  const [newsLoader, setNewsLoader] = useState(false);
  const [departmentDetails, setDepartmentDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [topNews, setTopNews] = useState([]);

  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const [error, setError] = useState(null);

  //#region Load Data
  const loadDepartmentDetails = () => {
    setLoading(true);
    PublicService.loadDepartmentDetails("dean-students-welfare")
      .then((response) => {
        setDepartmentDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError("Failed to load data");
      });
  };
  const getTopNotifications = () => {
    setNotificationsLoading(true);
    PublicService.topNewsNotifications(true, "dean-students-welfare")
      .then((response) => {
        setNotifications(response.data);
        setNotificationsLoading(false);
      })
      .catch((error) => {
        setNotificationsLoading(false);
        setError("Failed to load data");
      });
  };
  const getTopNews = () => {
    setNewsLoader(true);
    PublicService.topNewsNotifications(false, "dean-students-welfare")
      .then((response) => {
        setTopNews(response.data);
        setNewsLoader(false);
      })
      .catch((error) => {
        setNewsLoader(false);
        setError("Failed to load data");
      });
  };
  const handleClose = () => {
    setShowHodMessage(false);
  };

  //#endregion
  useEffect(() => {
    loadDepartmentDetails();
    getTopNotifications();
    getTopNews();
  }, []);
  return loading ? (
    <div style={{ height: "300px" }}>
      <div className="mt-3">
        <PageLoader />
      </div>
    </div>
  ) : (
    <div>
      <ImageBanner
        title={departmentDetails.name}
        links={["Home", departmentDetails.name]}
        bannerImg={
          departmentDetails.bannerImagePath
            ? process.env.REACT_APP_HOST_URL +
              "bannerImages/" +
              departmentDetails.bannerImagePath
            : "/images/banner4.jpg"
        }
      />
      {/* <DepartmentLinks /> */}
      <div className="container mb-0">
        <LineBottom
          className="mt-4"
          span="Sheikh-ul-Alam Memorial Degree College"
          text={departmentDetails.name}
        />
        <div className="container mb-4">
          <div className="row">
            <div className="col-md-8 text-justify">
              <LineBottom className="mt-4" span="About" text="Us" />
              <div
                dangerouslySetInnerHTML={{
                  __html: departmentDetails.about
                    ? departmentDetails.about
                    : "No information found",
                }}
              ></div>
            </div>
            {/* <div className="col-md-4 text-justify"> */}
            <Notification
              header={"Announcements"}
              loader={notificationsLoading}
              data={notifications || []}
              error={error}
              viewAllHref={`/all-notifications/${"dean-students-welfare"}`}
            />
            {/* </div> */}
          </div>
        </div>
        <div className="container mb-2 mt-md-5 mt-3">
          <div className="row">
            <NewsBar loader={newsLoader} data={topNews} error={error} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DSW;
