import "./NavBar.css";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import CollegeService from "../../../services/CollegeService";
import { useEffect, useState } from "react";
import "../../MainContent/Admission/Admission.css";
import DynamicPageService from "../../../services/DynamicPageService";
const NavBar = () => {
  const [services, setServices] = useState([]);
  const [serviceLoader, setServiceLoader] = useState(false);
  const [error, setError] = useState(false);

  const loadDynamicServices = () => {
    setServiceLoader(true);
    DynamicPageService.loadDynamicLinks()
      .then((response) => {
        setServices(response.data);
        setServiceLoader(false);
        setError(false);
      })
      .catch((error) => {
        setError(true);
        setServiceLoader(false);
      });
  };

  useEffect(() => {
    loadDynamicServices();
  }, []);
  const closeNavbar = () => {
    const navbar = document.getElementById("basic-navbar-nav");
    navbar.classList.remove("show");
  };
  return (
    <Navbar sticky="top" variant="dark" className="sp-navbar-green" expand="lg">
      <Container className="p-0">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => closeNavbar()} as={NavLink} to="/">
              Home
            </Nav.Link>
            <NavDropdown
              className="wiser-dropdown"
              title="Our College"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                as={NavLink}
                onClick={() => closeNavbar()}
                to="about-us"
              >
                About Us
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => closeNavbar()}
                as={NavLink}
                to="principal-message"
              >
                Principal's Message
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => closeNavbar()}
                as={NavLink}
                to="vision-mission"
              >
                Vision & Mission
              </NavDropdown.Item>
              {/* <NavDropdown.Item
                onClick={() => closeNavbar()}
                as={NavLink}
                to="organigram"
              >
                Organigram
              </NavDropdown.Item> */}
              {/* <NavDropdown.Item
                onClick={() => closeNavbar()}
                as={NavLink}
                to="Committee-list"
              >
                Committee List
              </NavDropdown.Item> */}
            </NavDropdown>

            {/* services */}
            {/* <Nav.Link onClick={() => closeNavbar()} as={NavLink} to="services">
              Services
            </Nav.Link> */}
            <NavDropdown
              className="wiser-dropdown"
              title="Facilities"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={() => closeNavbar()}
                as={NavLink}
                to="services"
              >
                Services
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => closeNavbar()}
                as={NavLink}
                to="activities"
              >
                Activities
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => closeNavbar()}
                as={NavLink}
                to="dean-students-welfare"
              >
                DSW
              </NavDropdown.Item>
            </NavDropdown>
            {/* Departments */}
            <NavDropdown
              className="wiser-dropdown"
              title="Academics"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={() => closeNavbar()}
                as={NavLink}
                to="college-departments"
              >
                Departments
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => closeNavbar()}
                as={NavLink}
                to="college-programmes"
              >
                Programmes
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => closeNavbar()}
                as={NavLink}
                to="/pages/examination"
              >
                Examination
              </NavDropdown.Item>
              {/* <NavDropdown.Item
                onClick={() => closeNavbar()}
                as={NavLink}
                to="admission"
              >
               Admission
              </NavDropdown.Item>               */}
            </NavDropdown>
            <Nav.Link
              onClick={() => closeNavbar()}
              as={NavLink}
              to="all-downloads"
            >
              Downloads
            </Nav.Link>

            <Nav.Link onClick={() => closeNavbar()} as={NavLink} to="/gallary">
              Gallery
            </Nav.Link>
            <Nav.Link
              onClick={() => closeNavbar()}
              as={NavLink}
              to="iqac-downloads"
            >
              IQAC
            </Nav.Link>
            <Nav.Link
              onClick={() => closeNavbar()}
              as={NavLink}
              to="college-alumini"
            >
              Alumni
            </Nav.Link>

            <NavDropdown
              className="wiser-dropdown"
              title="Other Pages"
              id="basic-nav-dropdown"
            >
              {services &&
                services.map((service, index) => (
                  <NavDropdown.Item
                    onClick={() => closeNavbar()}
                    as={NavLink}
                    to={"/pages/" + service.slug}
                    key={index}
                  >
                    {service.title}
                  </NavDropdown.Item>
                ))}
            </NavDropdown>
            {/* <Nav.Link href="#home">e-Content</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
