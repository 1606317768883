import { useEffect } from "react";
import { useState } from "react";
import Image from "react-bootstrap/Image";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CollegeService from "../../services/CollegeService";
import ErrorBox from "../utils/ErrorBox";
import ImageBanner from "../utils/ImageBanner";
import LineBottom from "../utils/LineBottom";
import PageLoader from "../utils/PageLoader";
const Alumini = () => {
  const [page, setPage] = useState({});
  const [loader, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadPage = () => {
    setLoading(true);
    CollegeService.getALuminiPage()
      .then((response) => {
        setPage(response.data);
        setError(false);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadPage();
  }, []);
  return loader ? (
    <div className="my-4">
      <PageLoader />
    </div>
  ) : error ? (
    <ErrorBox />
  ) : (
    <div className="Aboutpage_image">
      <div className="banner_image">
        <Image src="/images/banner.jpg" />
        <div className="banner-description">
          <h4>Alumni Association</h4>
          <div className="mt-1 banner-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Alumini Association</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="heading">
          <LineBottom
            span="Alumni"
            text="Sheikh-ul-Alam Memorial Degree College"
          />
        </div>
        <div
          className="Aboutpage_description"
          dangerouslySetInnerHTML={{ __html: page.content }}
        ></div>
        <div className="heading mt-3">
          <LineBottom span="Alumni" text="Registration form" />
        </div>
        <div dangerouslySetInnerHTML={{ __html: page.aluminiFormLink }}></div>
      </div>
    </div>
  );
};

export default Alumini;
