import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const DownloadDataGrid = ({ data }) => {
  const downloadButton = (cell) => {
    return (
      <span>
        <a target="_blank" className="download-link" href={cell}>
          <span className="download-text">Download</span>{" "}
          <FontAwesomeIcon className="downloadIcon" icon={faDownload} />
        </a>
      </span>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "S.No",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      sort: true,
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "departmentName",
      text: "Department Name",
      sort: true,
    },
    {
      dataField: "publishDateToView",
      text: "Uploaded On",
      sort: true,
    },

    {
      dataField: "pathLink",
      text: "Action",
      formatter: downloadButton,
    },
  ];

  return (
    <>
      {data.length && data.length ? (
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          pagination={paginationFactory(5)}
        />
      ) : (
        <div className="nodata-warning">
          <>There are no available downloads.</>
        </div>
      )}
    </>
  );
};

export default DownloadDataGrid;
