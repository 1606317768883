import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import WiserRouter from "./components/WiserRouter/WiserRouter";
import PublicService from "./services/PublicService";
import { useState, useEffect } from "react";
import PageLoader from "./components/utils/PageLoader";
const App = () => {
  const [instituteDetails, setInstituteDetails] = useState({});
  const [instituteLoader, setInstituteLoader] = useState(false);
  const getInstituteDetails = () => {
    setInstituteLoader(true);
    PublicService.getInstituteDetails()
      .then((response) => {
        setInstituteDetails(response.data);
        setInstituteLoader(false);
      })
      .catch((error) => {
        setInstituteLoader(false);
      });
  };
  useEffect(() => {
    getInstituteDetails();
  }, []);
  return (
    <Router>
      {instituteLoader ? (
        <PageLoader />
      ) : (
        <>
          <Header details={instituteDetails} />
          <WiserRouter details={instituteDetails} />
          <Footer details={instituteDetails} />
        </>
      )}
    </Router>
  );
};

export default App;
