import {
  faBars,
  faBell,
  faBellSlash,
  faBullhorn,
  faCarSide,
  faChartBar,
  faCreditCard,
  faMarsStroke,
  faSquareFull,
  faSquareRootAlt,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";
import LineBottom from "../../../utils/LineBottom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHornbill,
  faSquarespace,
  faStackExchange,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const StudentCorner = () => {
  return (
    <div className="col-lg-4 row-section">
      <section id="Dean">
        <LineBottom className="mt-4" span={"Student"} text="Corner" />
        <div className="content">
          <a
            className="cornerA"
            target="_new"
            href="https://egov.uok.edu.in/results/"
          >
            <div className="row">
              <div className="col-lg-2 col-sm-2 col-md-2 col-2">
                <div className="cornerIcon">
                  <FontAwesomeIcon icon={faChartBar} size="2x" />
                </div>
              </div>
              <div className="col-lg-10 col-sm-10 col-md-10 col-10 cornerText">
                <p>Results</p>
              </div>
            </div>
          </a>
          <a
            className="cornerA"
            href="https://scholarships.gov.in/"
            target="_new"
          >
            <div className="row">
              <div className="col-lg-2 col-sm-2 col-md-2 col-2">
                <div className="cornerIcon">
                  <FontAwesomeIcon icon={faCreditCard} size="2x" />
                </div>
              </div>
              <div className="col-lg-10 col-sm-10 col-md-10 col-10 cornerText">
                <p>Scholarships</p>
              </div>
            </div>
          </a>
          <Link className="cornerA" to="/pages/admissions">
            <div className="row">
              <div className="col-lg-2 col-sm-2 col-md-2 col-2">
                <div className="cornerIcon">
                  <FontAwesomeIcon icon={faBullhorn} size="2x" />
                </div>
              </div>
              <div className="col-lg-10 col-sm-10 col-md-10 col-10 cornerText">
                <p>Admissions</p>
              </div>
            </div>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default StudentCorner;
