import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ErrorBox from "../../../utils/ErrorBox";
import PageLoader from "../../../utils/PageLoader";
import PublicService from "../../../../services/PublicService";
import Downloads from "../../Home/Downloads/Downloads";
import UpcomingEvents from "../LinksStudentSay/UpcomingEvents";

const MessageSection = ({ loader, data, error, events, eventsLoader }) => {
  const [pmLoader, setPmLoader] = useState(false);
  const [pmMessage, setPmMessage] = useState({});
  const [pmError, setPmError] = useState(null);

  const loadPersonalityMessage = () => {
    setPmLoader(true);
    PublicService.getPersonalityMessage()
      .then((response) => {
        setPmMessage(response.data);
        setPmLoader(false);
      })
      .catch((error) => {
        setPmError("Failed to load data");
        setPmLoader(false);
      });
  };

  useEffect(() => {
    loadPersonalityMessage();
  }, []);

  return (
    <div style={{ marginTop: "2em" }} className="container mb-5">
      <div className="row section-row">
        <div className="col-lg-4 row-section">
          <section id="PrincipalsMessage">
            <h3 className="fs-5">
              <span>Principal's</span> Message
            </h3>
            <div className="line-bottom"></div>
            {loader ? (
              <PageLoader />
            ) : error ? (
              <ErrorBox text={"Failed to load message"} />
            ) : (
              <>
                <img
                  src={
                    data.principalPhoto
                      ? process.env.REACT_APP_HOST_URL +
                        "uploads/" +
                        data.principalPhoto
                      : "/images/corrupted.jpg"
                  }
                  className="img-fullwidth"
                  alt="Professor (Dr.) Khursheed Ahmad"
                />
                <p className="mt-3"></p>
                <p
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: data.principalMessage
                      ? data.principalMessage?.substring(0, 259) + "..."
                      : "",
                  }}
                ></p>
                <p className="mb-2"></p>
                <Link
                  to="/principal-message"
                  className="btn btn-primary btn-sm"
                >
                  Read more
                </Link>
              </>
            )}
          </section>
        </div>
        <div className="col-lg-4 row-section">
          <section id="About">
            <h3 className="fs-5">
              <span>About</span> College
            </h3>
            <div className="line-bottom"></div>
            {loader ? (
              <PageLoader />
            ) : error ? (
              <ErrorBox text={"Failed to load content"} />
            ) : (
              <>
                {/* <img
                  src={process.env.REACT_APP_APPURL + "images/about.jpg"}
                  className="img-fullwidth"
                  alt=""
                /> */}
                {/* <p className="mt-3"></p> */}
                <p
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: data.about?.substring(0, 241) + "..",
                  }}
                ></p>
                <p className="mb-2"></p>
                <Link to="/about-us" className="btn btn-primary btn-sm">
                  Read more
                </Link>
              </>
            )}
          </section>
        </div>

        <UpcomingEvents loader={eventsLoader} data={events} />
      </div>
    </div>
  );
};

export default MessageSection;
