import {
  faArrowLeft,
  faSearchPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "./Gallery.css";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import GalleryService from "../../../services/GalleryService";
import ErrorBox from "../../utils/ErrorBox";
import PageLoader from "../../utils/PageLoader";
import Pagination from "../../utils/Pagination";
import LineBottom from "../../utils/LineBottom";
import { fileExists } from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GalleryContent = () => {
  let { slug } = useParams();
  const [galleryContent, setGalleryContent] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [activeImagePath, setActiveImagePath] = useState("");
  const [activeImageCaption, setActiveImageCaption] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const getCurrentImage = (path, caption) => {
    setActiveImagePath(path);
    setActiveImageCaption(caption);
    setIsPreviewOpen(true);
  };

  const handleClose = () => {
    setIsPreviewOpen(false);
  };

  const handleClosePreview = (event) => {
    if (event.target.id === "previewImage") {
      setIsPreviewOpen(false);
    }
  };

  const loadGalleryContent = (pageNumber, pageSize) => {
    setLoaded(false);
    GalleryService.loadGalleryItemContent(slug, pageNumber, pageSize)
      .then((response) => {
        setGalleryContent(response);
        setLoaded(true);
        window.scroll(0, 0);
      })
      .catch((error) => {
        setError(error);
        setLoaded(true);
      });
  };

  const handleOnPageChange = (pageNumber) => {
    loadGalleryContent(pageNumber, galleryContent.pageSize);
  };

  useEffect(() => {
    loadGalleryContent(1, 20);
  }, [slug]);
  return (
    <>
      <div className="container px-lg-5 px-md-3 px-sm-2 my-5">
        <Link to={"/gallary"}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back to Gallery
        </Link>
        <LineBottom span="Gallery" text="Images" />
        {!loaded ? (
          <PageLoader />
        ) : error ? (
          <ErrorBox text="Failed to load data." />
        ) : (
          <div className="wrapper">
            <div className="container-fluid my-5 p-0">
              <div className="galleryContent-gallery">
                {galleryContent.data && galleryContent.data.length ? (
                  galleryContent.data.map((galleryItem) => (
                    <div
                      key={galleryItem.id}
                      className="galleryContent-imageWrapper"
                      onClick={() => {
                        getCurrentImage(galleryItem.path, galleryItem.caption);
                      }}
                    >
                      <img
                        className="galleryContent-image"
                        src={
                          process.env.REACT_APP_HOST_URL +
                          "images/" +
                          galleryItem.path
                        }
                        alt={galleryItem.title}
                      />
                      <div className="galleryContent-image-overlay">
                        <span className="zoom-icon">
                          <FontAwesomeIcon icon={faSearchPlus} />
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="badge badge-warning">
                    No images in this category.
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
        {galleryContent.data && (
          <Pagination
            firstPage={galleryContent.firstPage}
            lastPage={galleryContent.lastPage}
            currentPage={galleryContent.pageNumber}
            handlePageChange={handleOnPageChange}
          />
        )}
      </div>

      {isPreviewOpen && (
        <div
          id="previewImage"
          className="modal-overlay"
          onClick={handleClosePreview}
        >
          <div
            style={{ width: "fit-content" }}
            className="modal-body container"
          >
            <span onClick={handleClose} className="modal-close">
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <figure>
              <img
                className="active-image"
                src={
                  process.env.REACT_APP_HOST_URL + "images/" + activeImagePath
                }
                alt={activeImageCaption}
              />
              <figcaption className="image-caption">
                {activeImageCaption}
              </figcaption>
            </figure>
          </div>
        </div>
      )}
    </>
  );
};

export default GalleryContent;
