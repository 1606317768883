import { useState } from "react";
import { useEffect } from "react";
import "./Department.css";
import LineBottom from "../../utils/LineBottom";
import PageLoader from "../../utils/PageLoader";
import ErrorBox from "../../utils/ErrorBox";
import { Link, NavLink } from "react-router-dom";
import PublicService from "../../../services/PublicService";

const Department = () => {
  const [departmentCategories, setDepartmentCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadAllDepartments = () => {
    setLoading(true)
    PublicService.loadDepartments()
      .then((response) => {
        setDepartmentCategories(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError("Failed to load departments");
      });
  };

  useEffect(() => {
    loadAllDepartments();
  }, []);

  const renderDepartmentData = () => {
    return departmentCategories.map((depitem, index) => (
      depitem.name.toLowerCase() !== "others" && <div key={index} className="Department_departmentlist mb-5">
        <p className="Department-departmentheading mb-2 ">{depitem.name}</p>

        <div className="row">
          {depitem.departments.map((department, indexDep) => (
            <div key={indexDep} className="col-12 col-md-4 mt-1">
              <Link
                className="Department-departmentlinkitem"
                to={`/college-department-detail/${department.slug}`}
              >
                <button className="Department-departmentlinkitem">
                  {department.name}
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    ));
  };
  return (
    <div className="container mb-md-5 mb-3">
      <LineBottom className="mt-4" span="Academic" text="Departments" />
      {!loading ? (
        error ? (
          <ErrorBox text={error} />
        ) : (
          renderDepartmentData()
        )
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default Department;
