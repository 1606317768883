import { Link } from "react-router-dom";
import LineBottom from "../../../utils/LineBottom";

const CollegeTarana = () => {
  return (
    <div className="col-lg-4 row-section">
      <section id="Dean">
        <LineBottom className="mt-4" span="College" text="Tarana" />

        <iframe
          style={{ width: "100%" }}
          height="245"
          src="https://www.youtube.com/embed/lElPySjUeo0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
        <p className="mt-3"></p>
        <p className="text-justify">
          SAM College Budgam is a premier educational institution dedicated to
          providing a transformative learning experience to students, empowering
          them to excel academically.
        </p>
        <p className="mb-2"></p>
        <Link to="/about-us" className="btn btn-primary btn-sm">
          Read more
        </Link>
      </section>
    </div>
  );
};

export default CollegeTarana;
