import React, { useEffect, useState } from "react";
import "./Nirf.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import LineBottom from "../../utils/LineBottom";
import PageLoader from "../../utils/PageLoader";
import ErrorBox from "../../utils/ErrorBox";
import PublicService from "../../../services/PublicService";
import Pagination from "../../utils/Pagination";

const Nirf = () => {
  const [NirfData, setNirfData] = useState([]);
  const [NirfDataLoader, setNirfDataLoader] = useState(false);
  const [error, setError] = useState(false);

  const loadNirfDownloads = async (pageNumber, pageSize) => {
    setNirfDataLoader(false);
    PublicService.loadDownloads("Nirf", pageNumber, pageSize)
      .then((response) => {
        setNirfData(response);
        setNirfDataLoader(true);
      })
      .catch((error) => {
        setError("Failed to load Nirf Data");
        setNirfDataLoader(true);
      });
  };

  const handleOnPageChange = (pageNumber) => {
    loadNirfDownloads(pageNumber, NirfData.pageSize);
  };

  useEffect(() => {
    loadNirfDownloads(1, 10);
  }, []);

  const renderData = () => {
    return NirfData.data.map((nirf) => (
      <li key={nirf.id} className="nirfPage-list-item pl-0">
        <div className="nirfPage-iconnirfWrapper d-flex">
          <button className="nirfPage-iqacIcon ml-0">
            <FontAwesomeIcon icon={faArrowCircleRight} />
          </button>
          <div className="nirfPage-link">
            <a
              className="nirfPage-link-item"
              href={process.env.REACT_APP_HOST_URL + "uploads/" + nirf.path}
            >
              <span className="nirfPage-nirftitle px-2">{nirf.title}</span>
            </a>
          </div>
        </div>
        <a
          className="nirfPage-download-btn"
          href={process.env.REACT_APP_HOST_URL + "uploads/" + nirf.path}
        >
          <span className="nirfPage-downloadtext">Download</span>
          <FontAwesomeIcon icon={faDownload} />
        </a>
      </li>
    ));
  };

  return (
    <div className="container my-5">
      <LineBottom span="NIRF" text="Section" />
      <div className="container my-5">
        {NirfData.data && NirfData.data.length ? (
          <ul className="nirfPage-list p-0">
            {NirfDataLoader ? (
              error ? (
                <ErrorBox text={error} />
              ) : (
                renderData()
              )
            ) : (
              <PageLoader />
            )}
          </ul>
        ) : (
          <span className="badge badge-warning">No downloads available.</span>
        )}
      </div>
      {NirfData.pageNumber !== 0 && (
        <Pagination
          firstPage={NirfData.firstPage}
          lastPage={NirfData.lastPage}
          currentPage={NirfData.pageNumber}
          handlePageChange={handleOnPageChange}
        />
      )}
    </div>
  );
};
export default Nirf;
