import Downloads from "../Downloads/Downloads";
import UpcomingEvents from "../LinksStudentSay/UpcomingEvents";
import CollegeTarana from "./CollegeTarana";
import News from "./News";
import Notification from "./Notification";
import "./NotifyNewsSection.css";
import StudentCorner from "./StudentCorner";

const NotifyNewsSection = ({
  newsLoader,
  notificationsLoader,
  news,
  notifications,
  error,
  downloadsLoader,
  downloads,
}) => {
  return (
    <div className="section-wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <CollegeTarana />
          <Downloads loader={downloadsLoader} data={downloads} />
          <StudentCorner />
        </div>
      </div>
    </div>
  );
};

export default NotifyNewsSection;
