import React from "react";
import ErrorBox from "../../utils/ErrorBox";
import PageLoader from "../../utils/PageLoader";
import LineBottom from "../../utils/LineBottom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

const StaffMembers = ({ staff, loader, error, slug }) => {
  return loader ? (
    <PageLoader />
  ) : error ? (
    <ErrorBox />
  ) : (
    <div>
      <LineBottom className="mt-4" span="Staff " text={"Members"} />
      <div className="row justify-content-around mb-3">
        {staff.map((staffMember, index) => (
          <div
            key={staffMember.id}
            className="col-lg-3 col-md-5 card staff-card py-3 mt-4 mt-lg-0"
          >
            <div className="row d-flex justify-content-center">
              <div className="mt-2 d-flex justify-content-center">
                <div className="rounded-circle staff-card-image">
                  <img
                    className="card-img-top rounded-circle"
                    src={
                      staffMember.profileImage
                        ? process.env.REACT_APP_HOST_URL +
                          "/uploads/" +
                          staffMember.profileImage
                        : "/images/placeholder.jpg"
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="card-body">
              <h4 className="card-title text-center  border-top pt-2">
                {staffMember.fullName}
              </h4>
              <p className="card-text text-center">{staffMember.designation}</p>
              <p className="card-text text-center">
                ({staffMember.qualification})
              </p>
              <p className="card-text text-center border-top pt-2 mt-2">
                {staffMember.email}
              </p>
              {staffMember.resume ? (
                <p className="card-text text-center border-top pt-2 mt-2">
                  <a
                    target={"_blank"}
                    href={
                      process.env.REACT_APP_HOST_URL +
                      "uploads/" +
                      staffMember.resume
                    }
                  >
                    Resume
                  </a>
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
        <NavLink
          to={"/staff/" + slug}
          className="col-lg-1 col-md-5 col-12 mt-4 d-flex mt-lg-0 justify-content-center align-items-center"
        >
          <span className="border rounded-circle more-staff-icon">
            <FontAwesomeIcon className="more-icon" icon={faArrowRight} />
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default StaffMembers;
