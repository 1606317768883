import Breadcrumb from "react-bootstrap/Breadcrumb";
import Image from "react-bootstrap/Image";
const ImageBanner = ({ title, links, bannerImg }) => {
  return (
    <div className="banner_image">
      <Image
        style={{ maxHeight: "300px", objectFit: "cover" }}
        src={bannerImg}
      />
      <div className="banner-description">
        <h4>{title}</h4>
        <div className="mt-1 banner-breadcrumb">
          <Breadcrumb>
            {links.map((link, index) => {
              return (
                <Breadcrumb.Item key={index} href="#">
                  {link}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </div>
      </div>
    </div>
  );
};

export default ImageBanner;
