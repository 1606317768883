import http from "./HttpService";

const loadDownloads = async (departmentId = "") => {
  try {
    var response = await http.get(
      `download/get-all-downloads?departmentId=${departmentId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const DownloadService = {
  loadDownloads,
};

export default DownloadService;
