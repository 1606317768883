import React, { useState, useEffect } from "react";
import PublicService from "../../../services/PublicService";
import LineBottom from "../../utils/LineBottom";
import ErrorBox from "../../utils/ErrorBox";
import PageLoader from "../../utils/PageLoader";
import Pagination from "../../utils/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";

const AllNotifications = () => {
  let { slug } = useParams();
  const [notifications, setNotifications] = useState([]);
  const [loader, setLoader] = useState(false);
  const [departmentLoading, setDepartmentLoading] = useState(false);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const handleDepartmentChange = (event) => {
    setDepartmentId(event.target.value);
  };
  const loadAllDepartments = () => {
    setDepartmentLoading(true);
    PublicService.loadDepartments()
      .then((response) => {
        if (response.data) {
          let options = [];
          response.data.forEach((element) => {
            options = options.concat(element.departments);
          });
          setOptions(options);

          if (slug) {
            setDepartmentId(options.find((x) => x.slug === slug).id);
          }
        }
        setDepartmentLoading(false);
      })
      .catch((error) => {
        setDepartmentLoading(false);
        setError("Failed to load departments");
      });
  };
  const loadNotifications = (pageNumber, pageSize) => {
    setLoader(true);
    PublicService.NewsNotifications(true, pageNumber, pageSize, departmentId)
      .then((response) => {
        setNotifications(response);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setError("Failed to load data");
      });
  };

  const handleOnPageChange = (pageNumber) => {
    loadNotifications(pageNumber, notifications.pageSize);
  };
  useEffect(() => {
    loadAllDepartments();
  }, []);
  useEffect(() => {
    loadNotifications(1, 10);
    window.scroll(0, 0);
  }, [departmentId]);
  return (
    <div className="container my-5">
      <LineBottom span="All" text="Notifications" />
      <div className="container my-5">
        <div className="row">
          <div className="col-md-4 form-group">
            <label>Filter by Department</label>
            <select
              value={departmentId}
              onChange={handleDepartmentChange}
              className="form-control"
            >
              <option value="">--Select--</option>
              {options &&
                options.map((option, index) => {
                  return (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <ul className="nirfPage-list p-0">
          {!loader ? (
            error ? (
              <ErrorBox text={error} />
            ) : notifications.data ? (
              notifications.data.map((notice) => (
                <li key={notice.id} className="nirfPage-list-item pl-0">
                  <div className="nirfPage-iconnirfWrapper d-flex">
                    <button className="nirfPage-iqacIcon ml-0">
                      <FontAwesomeIcon icon={faArrowCircleRight} />
                    </button>
                    <div className="nirfPage-link">
                      {notice.hasContent ? (
                        <Link
                          className="nirfPage-link-item"
                          to={"/details/" + notice.slug}
                        >
                          <span className="nirfPage-nirftitle px-2">
                            {notice.title}
                          </span>
                        </Link>
                      ) : (
                        <a
                          rel="noreferrer"
                          target="_blank"
                          className="nirfPage-link-item"
                          href={
                            process.env.REACT_APP_HOST_URL +
                            "notifications/" +
                            notice.fileLink
                          }
                        >
                          <span className="nirfPage-nirftitle px-2">
                            {notice.title}
                          </span>
                        </a>
                      )}
                    </div>
                  </div>

                  <div>
                    <span
                      style={{
                        paddingRight: "10px",
                        fontStyle: "italic",
                        fontSize: "12px",
                      }}
                    >
                      {notice.departmentName}
                    </span>
                    {notice.hasContent ? (
                      <Link
                        className="nirfPage-download-btn"
                        to={"/details/" + notice.slug}
                      >
                        <span className="nirfPage-nirftitle px-2">
                          Download
                        </span>
                        <FontAwesomeIcon icon={faDownload} />
                      </Link>
                    ) : (
                      <a
                        target="_blank"
                        className="nirfPage-download-btn"
                        href={
                          process.env.REACT_APP_HOST_URL +
                          "notifications/" +
                          notice.fileLink
                        }
                      >
                        <span className="nirfPage-nirftitle px-2">
                          Download
                        </span>
                        <FontAwesomeIcon icon={faDownload} />
                      </a>
                    )}
                  </div>
                </li>
              ))
            ) : (
              <div className="p-2">No Data found</div>
            )
          ) : (
            <PageLoader />
          )}
        </ul>
      </div>
      {notifications.data && (
        <Pagination
          firstPage={notifications.firstPage}
          lastPage={notifications.lastPage}
          currentPage={notifications.pageNumber}
          handlePageChange={handleOnPageChange}
        />
      )}
    </div>
  );
};

export default AllNotifications;
