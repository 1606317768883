import { Carousel } from "react-bootstrap";
import ErrorBox from "../../../utils/ErrorBox";
import PageLoader from "../../../utils/PageLoader";
import "./WiserCarousal.css";
import DepartmentLinks from "../../Department/DepartmentLinks";
const WiserCarousal = ({ loader, data, error }) => {
  if (loader) {
    return <PageLoader />;
  } else {
    if (error) {
      return <ErrorBox text={"Failed to load images"} />;
    } else {
      return (
        <div className="wiser-carousal-wrapper">
          <Carousel className="wiser-carousal" fade>
            {data.map((record, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={
                    process.env.REACT_APP_HOST_URL +
                    "uploads/" +
                    record.imagePath
                  }
                  alt="First slide"
                />
                <Carousel.Caption>
                  <a href={record.link ? record.link : ""}>
                    <h4
                      style={{
                        fontWeight: "bolder",
                        textTransform: "uppercase",
                      }}
                    >
                      {record.caption}
                    </h4>
                  </a>
                  <p style={{ fontWeight: "bolder" }}>{record.description}</p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
          {/* {data && (
            <div className="carousal-buttons">
              <DepartmentLinks />
            </div>
          )} */}
        </div>
      );
    }
  }
};

export default WiserCarousal;
