import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faArrowCircleRight,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import PublicService from "../../../../services/PublicService";
import LineBottom from "../../../utils/LineBottom";
import ErrorBox from "../../../utils/ErrorBox";
import PageLoader from "../../../utils/PageLoader";
import Pagination from "../../../utils/Pagination";
import { dateFormat } from "../../../../config/config";
import HodMessage from "../../Department/HodMessage";
const AllEvents = () => {
  let { slug } = useParams();
  const [allEvents, setAllEvents] = useState([]);
  const [loader, setLoader] = useState(false);
  const [departmentLoading, setDepartmentLoading] = useState(false);

  const [error, setError] = useState(null);
  const [options, setOptions] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [showHodMessage, setShowHodMessage] = useState(false);
  const [eventBody, setEventBody] = useState("");

  const handleDepartmentChange = (event) => {
    setDepartmentId(event.target.value);
  };

  const loadAllDepartments = () => {
    setDepartmentLoading(true);
    PublicService.loadDepartments()
      .then((response) => {
        if (response.data) {
          let options = [];
          response.data.forEach((element) => {
            options = options.concat(element.departments);
          });
          setOptions(options);
          if (slug) {
            setDepartmentId(options.find((x) => x.slug === slug).id);
          }
        }
        setDepartmentLoading(false);
      })
      .catch((error) => {
        setDepartmentLoading(false);
        setError("Failed to load departments");
      });
  };
  //   const loadNews = (pageNumber, pageSize) => {
  //     setLoader(true);
  //     PublicService.NewsNotifications(false, pageNumber, pageSize, departmentId)
  //       .then((response) => {
  //         setAllNews(response);
  //         setLoader(false);
  //       })
  //       .catch((error) => {
  //         setLoader(false);
  //         setError("Failed to load data");
  //       });
  //   };
  const handleClose = () => {
    setEventBody("");
    setShowHodMessage(false);
  };
  const loadAllEvents = () => {
    setLoader(true);
    PublicService.getPublicEvents(departmentId)
      .then((response) => {
        setAllEvents(response);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setError("Failed to load data");
      });
  };

  useEffect(() => {
    loadAllDepartments();
  }, []);

  useEffect(() => {
    loadAllEvents();
    window.scroll(0, 0);
  }, [departmentId]);

  return (
    <div className="container my-5">
      <LineBottom span="All" text="Events" />
      <div className="container my-5">
        <div className="row">
          <div className="col-md-4 form-group">
            <label>Filter by Department</label>
            <select
              value={departmentId}
              onChange={handleDepartmentChange}
              className="form-control"
            >
              <option value="">--Select--</option>
              {options &&
                options.map((option, index) => {
                  return (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <ul className="nirfPage-list p-0">
          {!loader ? (
            error ? (
              <ErrorBox text={error} />
            ) : (
              <div className="all-news">
                <div style={{ marginTop: "20px" }} className="news-content">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Title</th>
                        <th>Location</th>
                        <th>Department</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allEvents.data &&
                        allEvents.data.map((event, index) => (
                          <tr key={index}>
                            <td>{dateFormat(event.eventDate)}</td>
                            <td>{event.title}</td>
                            <td>{event.location}</td>
                            <td>{event.departmentName}</td>
                            <td>
                              <Link to={"/event/" + event.slug}>
                                <span>View</span>{" "}
                                <FontAwesomeIcon icon={faEye} />
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )
          ) : (
            <PageLoader />
          )}
        </ul>
      </div>
      {/* <Pagination
        firstPage={allEvents.firstPage}
        lastPage={allEvents.lastPage}
        currentPage={allEvents.pageNumber}
        handlePageChange={handleOnPageChange}
      /> */}
      <HodMessage
        handleClose={handleClose}
        show={showHodMessage}
        body={eventBody}
        title={"Event Detail"}
      />
    </div>
  );
};

export default AllEvents;
