import TopBar from "./TopBar/TopBar";
import LogoBar from "./LogoBar/LogoBar";
import NavBar from "./NavBar/NavBar";
const Header = ({ details }) => {
  return (
    <>
      <TopBar />
      <LogoBar details={details} />
      <NavBar />
      {/* <p
        style={{ backgroundColor: "red", color: "white", textAlign: "center" }}
      >
        This website is under demo mode. All the content visible on this website
        is for testing purpose only. We will be live soon.
      </p> */}
    </>
  );
};

export default Header;
