import LineBottom from "../../../utils/LineBottom";
import {
  faBuilding,
  faArrowCircleRight,
  faListAlt,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageLoader from "../../../utils/PageLoader";
import ErrorBox from "../../../utils/ErrorBox";
import "./Downloads.css";
import { dateFormat } from "../../../../config/config";
const Downloads = ({ loader, data, error, span }) => {
  return (
    <div className="col-lg-4 row-section">
      <section id="Dean">
        <LineBottom className="mt-4" span={span || "Latest"} text="Downloads" />
        <div>
          {loader ? (
            <PageLoader />
          ) : error ? (
            <ErrorBox text={"Failed to load message"} />
          ) : data && data.length ? (
            <div
              style={{ marginBottom: "10px", overflowX: "scroll" }}
              className="newsWrapper"
            >
              <ul className="news-list download-wiser-list">
                {data.map((notification) => (
                  <li key={notification.id}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        process.env.REACT_APP_HOST_URL +
                        "uploads/" +
                        notification.path
                      }
                    >
                      {" "}
                      {notification.title}{" "}
                    </a>
                    <p className="date-of-news download-meta">
                      <span className="meta">
                        <FontAwesomeIcon icon={faListAlt} />{" "}
                        <span>{notification.downloadCategoryName}</span>
                      </span>
                      <span className="meta">
                        <FontAwesomeIcon icon={faBuilding} />{" "}
                        <span>{notification.departmentName || "General"}</span>
                      </span>
                      <span className="d-block">
                        <span
                          style={{ fontStyle: "italic", fontSize: "12px" }}
                          className="meta"
                        >
                          <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                          <span>{dateFormat(notification.publishDate)}</span>
                        </span>
                      </span>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <ul className="download-list">
              <li className="no-upcoming-events">
                <p>
                  <FontAwesomeIcon icon={faArrowCircleRight} /> No downloads
                  found
                </p>
              </li>
            </ul>
          )}
          {data && data.length ? (
            <a href="/all-downloads" className="btn btn-primary btn-sm">
              View All
            </a>
          ) : (
            ""
          )}
        </div>
      </section>
    </div>
  );
};

export default Downloads;
