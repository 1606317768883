import { useState } from "react";
import { useEffect } from "react";
import PublicService from "../../../services/PublicService";
import HomeCount from "./HomeCount";
import StudentSayBar from "./LinksStudentSay/StudentSayBar";
import MessageSection from "./MessageSection/MessageSection";
import NewsBar from "./NotifyNewsSection/NewsBar";
import NotifyNewsSection from "./NotifyNewsSection/NotifyNewsSection";
import WiserCarousal from "./WiserCarousal/WiserCarousal";
import Notification from "./NotifyNewsSection/Notification";

const Home = () => {
  const [allData, setAllData] = useState({});

  const [topNotifications, setTopNotifications] = useState([]);
  const [topNews, setTopNews] = useState([]);
  const [instituteDetails, setInstituteDetails] = useState({});
  const [topEvents, setTopEvents] = useState([]);
  const [topDownloads, setTopDownloads] = useState([]);
  const [topSliderImages, setTopSliderImages] = useState([]);

  const [newsLoader, setNewsLoader] = useState(false);
  const [notificationsLoader, setNotificationsLoader] = useState(false);
  const [instituteLoader, setInstituteLoader] = useState(false);
  const [eventsLoader, setEventsLoader] = useState(false);
  const [downloadsLoader, setDownloadsLoader] = useState(false);
  const [sliderLoader, setSliderLoader] = useState(false);

  const [error, setError] = useState(null);

  const getTopNotifications = () => {
    setNotificationsLoader(true);
    PublicService.topNewsNotifications(true, null)
      .then((response) => {
        setTopNotifications(response.data);
        setNotificationsLoader(false);
      })
      .catch((error) => {
        setNotificationsLoader(false);
        setError("Failed to load data");
      });
  };
  const getTopNews = () => {
    setNewsLoader(true);
    PublicService.topNewsNotifications(false, null)
      .then((response) => {
        setTopNews(response.data);
        setNewsLoader(false);
      })
      .catch((error) => {
        setNewsLoader(false);
        setError("Failed to load data");
      });
  };
  const getInstituteDetails = () => {
    setInstituteLoader(true);
    PublicService.getInstituteDetails()
      .then((response) => {
        setInstituteDetails(response.data);
        setInstituteLoader(false);
      })
      .catch((error) => {
        setInstituteLoader(false);
        setError("Failed to load data");
      });
  };
  const getTopEvents = () => {
    setEventsLoader(true);
    PublicService.topEvents()
      .then((response) => {
        setTopEvents(response.data);
        setEventsLoader(false);
      })
      .catch((error) => {
        setEventsLoader(false);
        setError("Failed to load data");
      });
  };

  const getTopDownloads = () => {
    setDownloadsLoader(true);
    PublicService.topDownloads()
      .then((response) => {
        setTopDownloads(response.data);
        setDownloadsLoader(false);
      })
      .catch((error) => {
        setDownloadsLoader(false);
        setError("Failed to load data");
      });
  };

  const getSliderImages = () => {
    setSliderLoader(true);
    PublicService.topSliderImages()
      .then((response) => {
        setTopSliderImages(response.data);
        setSliderLoader(false);
      })
      .catch((error) => {
        setSliderLoader(false);
        setError("Failed to load data");
      });
  };

  useEffect(() => {
    window.scroll(0, 0);
    // loadData();
    // loadTopNotifications();
    getSliderImages();
    getInstituteDetails();
    getTopNotifications();
    getTopNews();
    getTopEvents();
    getTopDownloads();
  }, []);
  return (
    <>
      <div className="container mt-2">
        <div className="row">
          <Notification
            loader={notificationsLoader}
            data={topNotifications || []}
            error={error}
          />
          <div className="col-lg-8 row-section">
            <WiserCarousal
              loader={sliderLoader}
              data={topSliderImages}
              error={error}
            />
          </div>
        </div>
      </div>
      <MessageSection
        data={instituteDetails}
        loader={instituteLoader}
        error={error}
        eventsLoader={eventsLoader}
        events={topEvents}
      />
      <StudentSayBar />
      <NotifyNewsSection
        newsLoader={newsLoader}
        notificationsLoader={notificationsLoader}
        error={error}
        news={topNews || []}
        notifications={topNotifications || []}
        data={allData}
        downloads={topDownloads}
        downloadsLoader={downloadsLoader}
      />

      {/* <HomeCount /> */}
      <NewsBar loader={newsLoader} data={topNews} error={error} />
    </>
  );
};

export default Home;
