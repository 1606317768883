import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PublicService from "../../../services/PublicService";
import PageLoader from "../../utils/PageLoader";
import ErrorBox from "../../utils/ErrorBox";
import "./NewsItem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBackward } from "@fortawesome/free-solid-svg-icons";
import { dateFormat } from "../../../config/config";

const NewsItem = () => {
  let { slug } = useParams();
  const [news, setNews] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const loadNews = () => {
    setLoader(true);
    PublicService.NewsNotificationBySlug(false, slug)
      .then((response) => {
        setNews(response.data);
        setLoader("Failed to load data");
        setLoader(false);
      })
      .catch((error) => {
        setError(true);
        setLoader(false);
      });
  };

  useEffect(() => {
    loadNews();
  }, [slug]);

  return !loader ? (
    error ? (
      <ErrorBox text={error} />
    ) : (
      <div className="container my-4">
        <div className="news-item-wrapper">
          {news.isNotice ? (
            <Link to={"/all-notifications"}>
              <FontAwesomeIcon icon={faArrowLeft} /> Back to all notifications
            </Link>
          ) : (
            <Link to={"/all-news"}>
              <FontAwesomeIcon icon={faArrowLeft} /> Back to all news
            </Link>
          )}
          <p className="location">
            {news.departmentName ? news.departmentName : "General"}
          </p>

          <h2 className="news-title">{news.title}</h2>
          <p className="publish-date">
            Publish date : {dateFormat(news.publishDate)}
          </p>
          <div dangerouslySetInnerHTML={{ __html: news.content }}></div>
          {news.fileLink && (
            <div style={{ marginTop: "20px" }}>
              <a
                href={
                  process.env.REACT_APP_HOST_URL +
                  "notifications/" +
                  news.fileLink
                }
              >
                Click here to download file
              </a>
            </div>
          )}
        </div>
      </div>
    )
  ) : (
    <div className="my-4">
      <PageLoader />
    </div>
  );
};

export default NewsItem;
