import { useEffect, useState } from "react";
import "./About.css";
import Image from "react-bootstrap/Image";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PublicService from "../../../../services/PublicService";
import PageLoader from "../../../utils/PageLoader";
import ErrorBox from "../../../utils/ErrorBox";
import LineBottom from "../../../utils/LineBottom";

const About = () => {
  const [instituteDetails, setInstituteDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const getInstituteDetails = () => {
    setLoader(true);
    PublicService.getInstituteDetails()
      .then((response) => {
        setInstituteDetails(response.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setError("Failed to load data");
      });
  };

  useEffect(() => {
    window.scroll(0, 0);
    getInstituteDetails();
  }, []);

  return loader ? (
    <div className="my-4">
      <PageLoader />
    </div>
  ) : error ? (
    <ErrorBox />
  ) : (
    <div className="Aboutpage_image">
      <div className="banner_image">
        <Image src="/images/banner.jpg" />
        <div className="banner-description">
          <h4>About Us</h4>
          <div className="mt-1 banner-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>About Us</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="heading">
          <LineBottom span="About" text="College" />
        </div>
        <div
          className="Aboutpage_description"
          dangerouslySetInnerHTML={{ __html: instituteDetails.about }}
        ></div>
      </div>
    </div>
  );
};

export default About;
