import http from "./HttpService";

const loadGallery = async (departmentId) => {
  try {
    var response = departmentId ? await http.get(`get-gallery-categories?departmentId=${departmentId}`) : await http.get("get-gallery-categories");
    return response.data;
  } catch (error) {
    throw error;
  }
}

const loadGalleryItemContent = async (slug, pageNumber, pageSize) => {
  try {
    var response = await (http.get(`public/gallery-images?slug=${slug}&pageNumber=${pageNumber}&pageSize=${pageSize}`));
    return response.data;
  } catch (error) {
    throw error;
  }
}

const GalleryService = {
  loadGallery,
  loadGalleryItemContent
}

export default GalleryService;