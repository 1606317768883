import "./Downloads.css";
import { useState, useEffect } from "react";
import ErrorBox from "../../utils/ErrorBox";
import PageLoader from "../../utils/PageLoader";
import DownloadService from "../../../services/DownloadService";
import { Accordion } from "react-bootstrap";
import DownloadDataGrid from "./DownloadDataGrid";
import { dateFormat } from "../../../config/config";
import PublicService from "../../../services/PublicService";
import { useParams } from "react-router-dom";

const Downloads = () => {
  let { slug } = useParams();
  const [departmentLoading, setDepartmentLoading] = useState(true);
  const [departmentId, setDepartmentId] = useState("");

  const [loaded, setLoaded] = useState(true);
  const [downloadData, setDownloadData] = useState([]);
  const [error, setError] = useState(false);
  let count = 0;
  const [options, setOptions] = useState([]);

  const handleDepartmentChange = (event) => {
    setDepartmentId(event.target.value);
  };
  const loadAllDepartments = () => {
    setDepartmentLoading(true);
    PublicService.loadDepartments()
      .then((response) => {
        if (response.data) {
          let options = [];
          response.data.forEach((element) => {
            options = options.concat(element.departments);
          });
          setOptions(options);
          if (slug) {
            setDepartmentId(options.find((x) => x.slug === slug).id);
          }
        }
        setDepartmentLoading(false);
      })
      .catch((error) => {
        setDepartmentLoading(false);
        setError("Failed to load departments");
      });
  };
  const loadData = () => {
    setLoaded(false);
    DownloadService.loadDownloads(departmentId)
      .then((response) => {
        setDownloadData(response.data);
        setLoaded(true);
      })
      .catch((error) => {
        setError(true);
        setLoaded(true);
      });
  };
  useEffect(() => {
    loadAllDepartments();
  }, []);
  useEffect(() => {
    loadData();
  }, [departmentId]);

  const getDownloads = (downloads) => {
    const downloadsReturn = downloads.map((download) => {
      download.pathLink =
        process.env.REACT_APP_HOST_URL + "uploads/" + download.path;
      download.publishDateToView = dateFormat(download.publishDate);
      return download;
    });

    return downloadsReturn;
  };
  const test = () => {};
  return (
    <div className="container my-5">
      <div className="my- section-row">
        <h3 className="fs-5">
          <span>College</span> Downloads
        </h3>
        <div className="line-bottom"></div>
      </div>
      <div className="row mb-3">
        <div className="col-md-4 form-group">
          <label>Filter by Department</label>
          <select
            value={departmentId}
            onChange={handleDepartmentChange}
            className="form-control"
          >
            <option value="">--Select--</option>
            {options &&
              options.map((option, index) => {
                return (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      {loaded == false ? (
        <PageLoader />
      ) : error ? (
        <ErrorBox text="Failed to load data." />
      ) : downloadData ? (
        <div className="downloadsPage-downloads mb-4">
          <Accordion defaultActiveKey="0">
            {downloadData.map((downloadRecord, index) => (
              <Accordion.Item
                className="downloadsPage-accordion-item mb-2"
                key={index}
                eventKey={index}
              >
                <Accordion.Header>
                  <span>Category Name :&nbsp; {test()}</span>
                  <span className="category-name">{downloadRecord.name}</span>
                </Accordion.Header>
                <Accordion.Body>
                  <DownloadDataGrid
                    data={getDownloads(downloadRecord.downloads)}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Downloads;
