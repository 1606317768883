const ContactUs = ({ details }) => {
  return (
    <div style={{ minHeight: "250px" }} className="container my-5">
      <div className="mb-4 section-row">
        <h3 className="fs-5">
          <span>Contact Us</span>
        </h3>
        <div className="line-bottom"></div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13226.57583092262!2d74.7173058!3d34.027349!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38e1911c1411d7d9%3A0xb111f4b191e047a1!2sGovt%20Degree%20College%20Budgam!5e0!3m2!1sen!2sin!4v1689756199440!5m2!1sen!2sin"
            width="100%"
            height={450}
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="col-md-4">
          <div className="contact-us-address">
            <div className="address-item">
              <h4>Postal Address</h4>
              <p>{details.address}</p>
            </div>
            <div className="address-item">
              <h4>Email</h4>
              <p>
                <a href="mailto:info@samdc.edu.in">{details.email}</a>
              </p>
            </div>
            <div className="address-item">
              <h4>Phone</h4>
              <p>{details.phoneNo}</p>
            </div>
            <div className="address-item">
              <h4>Fax</h4>
              <p>{details.fax}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
