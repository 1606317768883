import { useState, useEffect } from "react";
import ImageBanner from "../../utils/ImageBanner";
import LineBottom from "../../utils/LineBottom";
import Downloads from "../Home/Downloads/Downloads";
import UpcomingEvents from "../Home/LinksStudentSay/UpcomingEvents";
import News from "../Home/NotifyNewsSection/News";
import Notification from "../Home/NotifyNewsSection/Notification";
import "./Department.css";
import DepartmentLinks from "./DepartmentLinks";
import DepartmentSections from "./DepartmentSections";
import { useParams } from "react-router-dom";
import PublicService from "../../../services/PublicService";
import PageLoader from "../../utils/PageLoader";
import StaffMembers from "./StaffMembers";
import HodMessage from "./HodMessage";

const DepartmentDetail = () => {
  let { slug } = useParams();
  const [showHodMessage, setShowHodMessage] = useState(false);
  const [departmentDetails, setDepartmentDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const [newsLoading, setNewsLoading] = useState(false);
  const [departmentNews, setDepartmentNews] = useState([]);

  const [downloadsLoaded, setDownloadsLoaded] = useState(false);
  const [departmentDownloads, setDepartmentDownloads] = useState([]);

  const [eventsLoading, setEventsLoading] = useState(false);
  const [departmentEvents, setDepartmentEvents] = useState([]);

  const [error, setError] = useState(null);

  //#region Load Data
  const loadDepartmentDetails = () => {
    setLoading(true);
    PublicService.loadDepartmentDetails(slug)
      .then((response) => {
        setDepartmentDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError("Failed to load data");
      });
  };
  const getTopNotifications = () => {
    setNotificationsLoading(true);
    PublicService.topNewsNotifications(true, slug)
      .then((response) => {
        setNotifications(response.data);
        setNotificationsLoading(false);
      })
      .catch((error) => {
        setNotificationsLoading(false);
        setError("Failed to load data");
      });
  };

  const getTopNews = () => {
    setNewsLoading(true);
    PublicService.topNewsNotifications(false, slug)
      .then((response) => {
        setDepartmentNews(response.data);
        setNewsLoading(false);
      })
      .catch((error) => {
        setNewsLoading(false);
        setError("Failed to load data");
      });
  };
  const getTopEvents = () => {
    setEventsLoading(true);
    PublicService.topEvents(slug)
      .then((response) => {
        setDepartmentEvents(response.data);
        setEventsLoading(false);
      })
      .catch((error) => {
        setEventsLoading(false);
        setError("Failed to load data");
      });
  };
  const getTopDownloads = () => {
    setDownloadsLoaded(true);
    PublicService.topDownloads(slug)
      .then((response) => {
        setDepartmentDownloads(response.data);
        setDownloadsLoaded(false);
      })
      .catch((error) => {
        setDownloadsLoaded(false);
        setError("Failed to load data");
      });
  };
  const handleClose = () => {
    setShowHodMessage(false);
  };
  //#endregion
  useEffect(() => {
    loadDepartmentDetails();
    getTopNotifications();
    getTopNews();
    getTopEvents();
    getTopDownloads();
  }, [slug]);
  return loading ? (
    <div style={{ height: "300px" }}>
      <PageLoader />
    </div>
  ) : (
    <div>
      <ImageBanner
        title={departmentDetails.name}
        links={["Home", "Department", departmentDetails.name]}
        bannerImg={
          departmentDetails.bannerImagePath
            ? process.env.REACT_APP_HOST_URL +
              "bannerImages/" +
              departmentDetails.bannerImagePath
            : "/images/banner4.jpg"
        }
      />
      {/* <DepartmentLinks /> */}
      <div className="container mb-0">
        <LineBottom
          className="mt-4"
          span="Department of"
          text={departmentDetails.name}
        />
        <div className="container mb-4">
          <div className="row">
            <div className="col-md-8 text-justify">
              <LineBottom className="mt-4" span="About" text="Us" />
              <div
                dangerouslySetInnerHTML={{
                  __html: departmentDetails.about
                    ? departmentDetails.about
                    : "No information found",
                }}
              ></div>
            </div>
            <div className="col-md-4 text-justify">
              <LineBottom className="mt-4" span="HOD's" text="Message" />
              <img
                className="rounded hodimage"
                src={
                  departmentDetails.hodImagePath
                    ? process.env.REACT_APP_HOST_URL +
                      "uploads/" +
                      departmentDetails.hodImagePath
                    : "/images/placeholder.jpg"
                }
                alt="HOD"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: departmentDetails.hodMessage
                    ? departmentDetails.hodMessage?.substring(0, 400) + ".."
                    : "No message found",
                }}
              ></div>

              {departmentDetails.hodMessage &&
                departmentDetails.hodMessage.length > 400 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setShowHodMessage(true)}
                  >
                    Read more
                  </button>
                )}
            </div>
          </div>
        </div>
        <div className="container mb-4">
          <div className="row">
            <Notification
              span="Department"
              loader={notificationsLoading}
              data={notifications || []}
              error={error}
              viewAllHref={`/all-notifications/${slug}`}
            />

            <div className="col-md-8">
              <DepartmentSections
                data={departmentDetails.departmentSections}
                name={departmentDetails.name}
              />
            </div>
          </div>

          <div className="row mt-5">
            <News
              span="Department"
              loader={newsLoading}
              data={departmentNews || []}
              error={error}
              viewAllHref={`/all-news/${slug}`}
            />
            <Downloads
              loader={downloadsLoaded}
              error={error}
              span="Department"
              data={departmentDownloads || []}
            />
            <UpcomingEvents
              loader={eventsLoading}
              error={error}
              span="Department Upcoming"
              data={departmentEvents || []}
              slug={slug}
            />
          </div>
        </div>
        <div className="container my-4">
          <StaffMembers
            slug={slug}
            staff={departmentDetails.staffMembers || []}
          />
        </div>
      </div>
      <HodMessage
        handleClose={handleClose}
        show={showHodMessage}
        body={departmentDetails.hodMessage}
        title={"HOD's Message"}
      />
    </div>
  );
};

export default DepartmentDetail;
