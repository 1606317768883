import { useState, useEffect } from "react";
import ImageBanner from "../../utils/ImageBanner";
import LineBottom from "../../utils/LineBottom";
import Downloads from "../Home/Downloads/Downloads";
import UpcomingEvents from "../Home/LinksStudentSay/UpcomingEvents";
import News from "../Home/NotifyNewsSection/News";
import Notification from "../Home/NotifyNewsSection/Notification";
import "./Department.css";
import DepartmentLinks from "./DepartmentLinks";
import DepartmentSections from "./DepartmentSections";
import { Link, useParams } from "react-router-dom";
import PublicService from "../../../services/PublicService";
import PageLoader from "../../utils/PageLoader";
import StaffMembers from "./StaffMembers";
import HodMessage from "./HodMessage";

const Library = () => {
  let { slug } = useParams();
  const [showHodMessage, setShowHodMessage] = useState(false);
  const [departmentDetails, setDepartmentDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const [eventsLoading, setEventsLoading] = useState(false);
  const [departmentEvents, setDepartmentEvents] = useState([]);

  const [serviceLoading, setServiceLoading] = useState(false);
  const [services, setServices] = useState([]);

  const [error, setError] = useState(null);

  //#region Load Data
  const loadDepartmentDetails = () => {
    setLoading(true);
    PublicService.loadDepartmentDetails("Library")
      .then((response) => {
        setDepartmentDetails(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError("Failed to load data");
      });
  };
  const getTopNotifications = () => {
    setNotificationsLoading(true);
    PublicService.topNewsNotifications(true, "Library")
      .then((response) => {
        setNotifications(response.data);
        setNotificationsLoading(false);
      })
      .catch((error) => {
        setNotificationsLoading(false);
        setError("Failed to load data");
      });
  };
  const getTopEvents = () => {
    setEventsLoading(true);
    PublicService.topEvents("Library")
      .then((response) => {
        setDepartmentEvents(response.data);
        setEventsLoading(false);
      })
      .catch((error) => {
        setEventsLoading(false);
        setError("Failed to load data");
      });
  };
  const handleClose = () => {
    setShowHodMessage(false);
  };
  const loadLibraryServices = () => {
    setServiceLoading(true);
    PublicService.loadLibraryServices()
      .then((response) => {
        setServices(response.data);
        setServiceLoading(false);
      })
      .catch((error) => {
        setServiceLoading(false);
        setError("Failed to load data");
      });
  };

  //#endregion
  useEffect(() => {
    loadDepartmentDetails();
    getTopNotifications();
    getTopEvents();
    loadLibraryServices();
  }, []);
  return loading ? (
    <div style={{ height: "300px" }}>
      <div className="mt-3">
        <PageLoader />
      </div>
    </div>
  ) : (
    <div>
      <ImageBanner
        title={departmentDetails.name}
        links={["Home", departmentDetails.name]}
        bannerImg={
          departmentDetails.bannerImagePath
            ? process.env.REACT_APP_HOST_URL +
              "bannerImages/" +
              departmentDetails.bannerImagePath
            : "/images/banner4.jpg"
        }
      />
      <DepartmentLinks />
      <div className="container mb-0">
        <LineBottom
          className="mt-4"
          span="Sheikh-ul-Alam Memorial Degree College"
          text={departmentDetails.name}
        />
        <div className="container mb-4">
          <div className="row">
            <div className="col-md-8 text-justify">
              <LineBottom className="mt-4" span="About" text="Us" />
              <div
                dangerouslySetInnerHTML={{
                  __html: departmentDetails.about
                    ? departmentDetails.about
                    : "No information found",
                }}
              ></div>
            </div>
            <div className="col-md-4 text-justify">
              <LineBottom className="mt-4" span="Librarian's" text="Message" />
              <img
                className="rounded hodimage"
                src={
                  departmentDetails.hodImagePath
                    ? process.env.REACT_APP_HOST_URL +
                      "uploads/" +
                      departmentDetails.hodImagePath
                    : "/images/placeholder.jpg"
                }
                alt="Librarian"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: departmentDetails.hodMessage
                    ? departmentDetails.hodMessage?.substring(0, 400) + ".."
                    : "No message found",
                }}
              ></div>

              {departmentDetails.hodMessage &&
                departmentDetails.hodMessage.length > 400 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setShowHodMessage(true)}
                  >
                    Read more
                  </button>
                )}
            </div>
          </div>
        </div>
        <div className="container mb-2 mt-md-5 mt-3">
          <div className="row">
            <div className="col-lg-4">
              <LineBottom className="mt-4" span={"Our"} text={"Services"} />
              <div>
                {serviceLoading ? (
                  <div className="mt-4">
                    <PageLoader />
                  </div>
                ) : (
                  <div className="services d-flex flex-column">
                    {services &&
                      services.map((service, index) => (
                        <Link to={"/services/" + service.slug}>
                          <div
                            className="d-inline-block service-item py-3 px-4 mb-2 text-white w-md-75 w-100"
                            key={index}
                          >
                            <p>{service.name}</p>
                          </div>
                        </Link>
                      ))}
                    <Link to={"/college-library"}>
                      <div className="d-inline-block service-item py-3 px-4 mb-2 text-white w-md-75 w-100">
                        <p>Library Search</p>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <Notification
              header={"Announcements"}
              loader={notificationsLoading}
              data={notifications || []}
              error={error}
              viewAllHref={`/all-notifications/${"Library"}`}
            />
            <UpcomingEvents
              loader={eventsLoading}
              error={error}
              span="Upcoming"
              data={departmentEvents || []}
              slug={"Library"}
            />
          </div>
        </div>
        <div className="container my-4">
          <StaffMembers
            slug={"Library"}
            staff={departmentDetails.staffMembers || []}
          />
        </div>
      </div>
      <HodMessage
        handleClose={handleClose}
        show={showHodMessage}
        body={departmentDetails.hodMessage}
        title={"Librarian's Message"}
      />
    </div>
  );
};

export default Library;
