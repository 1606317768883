import { Button, Modal } from "react-bootstrap";

const HodMessage = ({ title, show, handleClose, body }) => {
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ textAlign: "justify" }}
          dangerouslySetInnerHTML={{
            __html: body ? body : "No message found",
          }}
        ></div>
      </Modal.Body>
    </Modal>
  );
};

export default HodMessage;
