import {
  faArrowCircleRight,
  faBuilding,
  faCalendarAlt,
  faHospitalAlt,
  faMapMarker,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageLoader from "../../../utils/PageLoader";
import ErrorBox from "../../../utils/ErrorBox";
import LineBottom from "../../../utils/LineBottom";
import {
  dateFormat,
  ifToday,
  Day,
  DateNum,
  Month,
} from "../../../../config/config";
import { Link } from "react-router-dom";

const UpcomingEvents = ({ slug, data, error, loader, span }) => {
  return (
    <div className="col-lg-4 row-section">
      <section id="Dean">
        <LineBottom className="mt-4" span={span || "Upcoming"} text="Events" />
        {loader ? (
          <PageLoader />
        ) : error ? (
          <ErrorBox text={"Failed to load message"} />
        ) : data && data.length === 0 ? (
          <ul className="download-list">
            <li className="no-upcoming-events">
              <p>
                <FontAwesomeIcon icon={faArrowCircleRight} /> No Upcoming events
                found
              </p>
            </li>
          </ul>
        ) : (
          <>
            <ul className="download-list-event">
              {data &&
                data.map((event, index) => (
                  <li key={index}>
                    <div className="row">
                      <div className="col-md-2 col-2 event-date">
                        <p>{Day(event.eventDate)}</p>
                        <h1>{DateNum(event.eventDate)}</h1>
                        <p>{Month(event.eventDate)}</p>
                      </div>
                      <div className="col-md-10 col-10">
                        <div className="event-detail-text">
                          {ifToday(event.createdDate) ? (
                            <p data-new="new" className="event-title">
                              {event.title}
                            </p>
                          ) : (
                            <p className="event-title">{event.title}</p>
                          )}

                          <p className="category-date-text">
                            <span>
                              <FontAwesomeIcon icon={faUniversity} />{" "}
                              {event.departmentName
                                ? "Department of " + event.departmentName
                                : "General"}
                            </span>
                          </p>
                          <p className="category-date-text">
                            <span>
                              <FontAwesomeIcon icon={faMapMarker} />{" "}
                              {event.location}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
            {data && data.length ? (
              <Link
                to={slug ? "/all-events/" + slug : "/all-events"}
                className="btn btn-primary btn-sm"
              >
                View All
              </Link>
            ) : (
              ""
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default UpcomingEvents;
