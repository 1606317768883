import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CollegeService from "../../../services/CollegeService";
import ErrorBox from "../../utils/ErrorBox";
import PageLoader from "../../utils/PageLoader";
import "../CCollegeService/CCollegeService.css";

const Activity = () => {
  let { slug } = useParams();
  const [service, setService] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadServiceItem();
  }, [slug]);
  const loadServiceItem = () => {
    setLoaded(false);
    CollegeService.loadActivityItem(slug)
      .then((response) => {
        setService(response.data);
        setLoaded(true);
      })
      .catch((error) => {
        setError(true);
        setLoaded(true);
      });
  };

  if (!loaded) {
    return (
      <div style={{ minHeight: "250px" }}>
        <PageLoader />
      </div>
    );
  } else {
    if (error) {
      return <ErrorBox text="Failed to load the service data" />;
    } else {
      return (
        <div style={{ minHeight: "250px" }} className="container my-5">
          <div className="mb-4 section-row">
            <h3 className="fs-5">
              <span>{service.title}</span>
            </h3>
            <div className="line-bottom"></div>
          </div>
          <div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                fontStyle: "italic",
                color: "#266470",
              }}
            >
              {service.departmentName} - {service.activityTypeName}
            </p>
          </div>
          {service.content ? (
            <div
              className="CCollegeService-ServiceData"
              dangerouslySetInnerHTML={{ __html: service.content }}
            />
          ) : (
            "No content found"
          )}
        </div>
      );
    }
  }
};

export default Activity;
