import { useState, useEffect } from "react";
import DynamicPageService from "../../../services/DynamicPageService";
import DynamicPage from "../../common/DynamicPage";
import ErrorBox from "../../utils/ErrorBox";
import PageLoader from "../../utils/PageLoader";
const Programs = () => {  
  const [service, setService] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadServiceItem("programs");
  },[]);
  const loadServiceItem = () => {
    setLoaded(false);
    DynamicPageService.loadDynamicPageItem("programs")
      .then((response) => {        
        setService(response.data);
        setLoaded(true);
      })
      .catch((error) => {
        setError(true);
        setLoaded(true);
      });
  };
 if (!loaded) {
    return (
      <div style={{ minHeight: "250px" }}>
        <PageLoader />
      </div>
    );
  } else {
    if (error) {
      return <ErrorBox text="Failed to load the service data" />;
    } else {
      return <DynamicPage service={service}/>
    }
  }
}; 

export default Programs;
