import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GalleryService from "../../../services/GalleryService";
import ErrorBox from "../../utils/ErrorBox";
import PageLoader from "../../utils/PageLoader";
import "./Gallery.css";

const Gallary = () => {
  const [loaded, setLoaded] = useState(false);
  const [galleryCategories, setGalleryCategories] = useState([]);
  const [error, setError] = useState(false);

  const loadGalleryCategories = () => {
    setLoaded(false);
    GalleryService.loadGallery()
      .then((response) => {
        setGalleryCategories(response.data);
        setLoaded(true);
        window.scroll(0, 0);
      })
      .catch((error) => {
        setError(true);
        setLoaded(true);
      });
  };

  useEffect(() => {
    loadGalleryCategories();
  }, []);

  return (
    <div className="container mb-3 mb-md-5">
      <div className="gallery-heading mt-5 section-row">
        <h3 className="fs-5">
          <span>Image</span> Gallery
        </h3>
        <div className="line-bottom"></div>
      </div>

      {!loaded ? (
        <PageLoader />
      ) : error ? (
        <ErrorBox text="Failed to load data." />
      ) : (
        <div className="row mb-5">
          {galleryCategories.map((category) => (
            <div key={category.id} className="col-6 col-md-4 col-lg-2 mt-3">
              <Link
                className="galleryPage-galleryItem"
                to={"/gallery/item/" + category.slug}
              >
                <img src={"/images/spcgallery.png"} alt={category.name} />
                <span className="d-block text-center mt-2 text-dark">
                  {category.name}
                </span>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Gallary;
