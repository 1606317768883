import http from "./HttpService";
const loadServiceItem = async (slug) => {
  try {
    const response = await http.get(`public/service-item?slug=${slug}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const loadServices = async () => {
  try {
    const response = await http.get("public/services");
    return response.data;
  } catch (error) {
    throw error;
  }
};
const loadSkills = async () => {
  try {
    const response = await http.get("skill/get-skills");
    return response.data;
  } catch (error) {
    throw error;
  }
};
const loadSkillPosts = async (skill_InitiativeId = "") => {
  try {
    const response = await http.get(
      `skill/get-public-skill-posts?skill_InitiativeId=${skill_InitiativeId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const loadSkillPostItem = async (slug) => {
  try {
    const response = await http.get(`skill/get-skill-post-item?slug=${slug}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getALuminiPage = async (slug) => {
  try {
    const response = await http.get(`public/alumini-page`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getActivities = async () => {
  try {
    const response = await http.get(`Activity/get-activities`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const loadActivityItem = async (slug) => {
  try {
    const response = await http.get(`Activity/get-activity-item?slug=${slug}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const CollegeService = {
  loadServiceItem,
  loadServices,
  loadSkills,
  loadSkillPosts,
  loadSkillPostItem,
  getALuminiPage,
  getActivities,
  loadActivityItem,
};

export default CollegeService;
