import http from "./HttpService";
const loadOrganigram = async () => {
  try {
    const response = await http.get(`organigram/get-organigram-images`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const OrganigramService = {
    loadOrganigram  
  };
  export default OrganigramService;
  