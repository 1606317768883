import React, { useEffect, useState } from 'react'
import Image from "react-bootstrap/Image";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PublicService from '../../../../services/PublicService';
import PageLoader from '../../../utils/PageLoader';
import ErrorBox from '../../../utils/ErrorBox';
import LineBottom from '../../../utils/LineBottom';
import { NavLink } from "react-router-dom";

const VisionMission = () => {

  const [instituteDetails, setInstituteDetails] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const getInstituteDetails = () => {
    setLoader(true);
    PublicService.getInstituteDetails()
      .then((response) => {
        setInstituteDetails(response.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setError("Failed to load data");
      });
  };

  useEffect(() => {
    window.scroll(0, 0);
    getInstituteDetails();
  }, [])


  return (
    <div className='pm'>
      <div className="banner_image">
        <Image src="/images/banner.jpg" />
        <div className="banner-description">
          <h4>Vision & Mission</h4>
          <div className="mt-1 banner-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item as={NavLink} to="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Vision & Mission</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      {loader ? <PageLoader /> :
        error ? <ErrorBox /> :
          <div>
            <div className="container my-5">
              <div className='heading'>
                <LineBottom span="Our" text="Vision" />
              </div>
              <div
                className="Aboutpage_description"
                dangerouslySetInnerHTML={{ __html: instituteDetails.vision }}>
              </div>
            </div>
            <div className="container my-5">
              <div className='heading'>
                <LineBottom span="Our" text="Mission" />
              </div>
              <div
                className="Aboutpage_description"
                dangerouslySetInnerHTML={{ __html: instituteDetails.mission }}>
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default VisionMission;