import {
  faBuilding,
  faCalendarAlt,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageLoader from "../../../utils/PageLoader";
import ErrorBox from "../../../utils/ErrorBox";
import LineBottom from "../../../utils/LineBottom";
import { dateFormat, ifToday } from "../../../../config/config";
import { Link } from "react-router-dom";
import { useRef } from "react";

const Notification = ({ loader, data, error, span, viewAllHref, header }) => {
  const marqueeRef = useRef(null);
  const startList = () => {
    marqueeRef.current.start();
  };

  const stopList = () => {
    marqueeRef.current.stop();
  };

  return (
    <div className="col-lg-4 row-section">
      <section id="PrincipalsMessage">
        <LineBottom
          className="mt-4"
          span={span || "Latest"}
          text={header || "Notifications"}
        />
        <div>
          {loader ? (
            <PageLoader />
          ) : error ? (
            <ErrorBox text={"Failed to load message"} />
          ) : data.length === 0 ? (
            <ul className="download-list">
              <li className="no-upcoming-events">
                <p>
                  <FontAwesomeIcon icon={faArrowCircleRight} /> No Notifications
                  found
                </p>
              </li>
            </ul>
          ) : (
            <div className="newsWrapper">
              <marquee
                ref={marqueeRef}
                onMouseOver={stopList}
                onMouseOut={startList}
                behavior="scroll"
                direction="up"
              >
                <ul className="news-list">
                  {data.map((notification) => (
                    <li key={notification.id}>
                      {ifToday(notification.publishDate) ? (
                        notification.hasContent ? (
                          <Link
                            data-new="new"
                            to={"/details/" + notification.slug}
                          >
                            <span className="nirfPage-nirftitle px-2">
                              {notification.title}
                            </span>
                          </Link>
                        ) : (
                          <a
                            data-new="new"
                            rel="noreferrer"
                            href={
                              process.env.REACT_APP_HOST_URL +
                              "notifications/" +
                              notification.fileLink
                            }
                            target="_blank"
                          >
                            {notification.title}
                          </a>
                        )
                      ) : notification.hasContent ? (
                        <Link to={"/details/" + notification.slug}>
                          <span className="nirfPage-nirftitle px-2">
                            {notification.title}
                          </span>
                        </Link>
                      ) : (
                        <a
                          rel="noreferrer"
                          href={
                            process.env.REACT_APP_HOST_URL +
                            "notifications/" +
                            notification.fileLink
                          }
                          target="_blank"
                        >
                          {notification.title}
                        </a>
                      )}

                      <p className="date-of-news">
                        <span className="meta">
                          <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                          <span>{dateFormat(notification.publishDate)}</span>
                        </span>
                        {" | "}
                        <span className="meta">
                          <FontAwesomeIcon icon={faBuilding} />{" "}
                          <span>
                            {notification.departmentName || "General"}
                          </span>
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
              </marquee>
            </div>
          )}
          <p className="mb-2"></p>
          {data && data.length ? (
            <Link
              to={viewAllHref ? viewAllHref : "/all-notifications"}
              className="btn btn-primary btn-sm"
            >
              View All
            </Link>
          ) : (
            ""
          )}
        </div>
      </section>
    </div>
  );
};

export default Notification;
