import http from "./HttpService";
const getIQACLinks = async () => {
  try {
    const response = await http.get(`iqac/get-public-iqac-links`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getIQACContent = async (Id) => {
  try {
    const response = await http.get(`iqac/get-public-iqac-content?id=${Id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getIQACUploads = async (Id) => {
  try {
    const response = await http.get(`iqac/get-public-iqac-uploads?id=${Id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getIQACContentbySlug = async (slug) => {
  try {
    const response = await http.get(
      `iqac/get-public-iqac-content-by-slug?slug=${slug}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getIQACUploadsBySlug = async (slug) => {
  try {
    const response = await http.get(
      `iqac/get-public-iqac-uploads-by-slug?slug=${slug}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const IqacService = {
  getIQACLinks,
  getIQACContent,
  getIQACUploads,
  getIQACContentbySlug,
  getIQACUploadsBySlug,
};

export default IqacService;
