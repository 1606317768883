const TableData=({dataList,listkeys,listText,Onswitch,handleEdithAction,handleDeleteAction,handleOnSwitch})=>{
    const RenderHeader=()=>{
        return(
            <thead>
                <tr>
                    <th>S.No</th>
                    {listText &&
                    listText.map((keyText,index)=>{
                        return <th key={index}>{keyText}</th>
                    })}                    
                </tr>
            </thead>
        )
    }
    const RenderData=()=>{
         if(dataList && dataList.length>0){            
            const data = dataList.sort((a, b) => a.name > b.name ? 1 : -1).map((item,index)=>{
               
                return(
                  <tr key={index}>
                    <td>{index+1}</td>
                    {listkeys.map((keyitem,index1)=>{
                        return <td key={index1}>{item[keyitem]}</td>
                    })}                                   
                </tr>
                )
           })
           return data;
        }else{
            return <td>No Record found</td>
        }
    }
    return(        
        <table className="table">
            {RenderHeader()}
            <tbody>
            {RenderData()}
            </tbody>
        </table>
    )
}
export default TableData;
