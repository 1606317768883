import {
  faEnvelope,
  faMap,
  faMapMarked,
  faMapMarkedAlt,
  faMapMarker,
  faMapMarkerAlt,
  faPhone,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./LogoBar.css";
const LogoBar = ({ details = {} }) => {
  return (
    <div className="logo-bar">
      <div className="container">
        <div className="logo-bar-row">
          <div className="row">
            <div className="col-md-4 col-12 text-center">
              <img
                className="logoImg"
                src={process.env.REACT_APP_APPURL + "images/logo.png"}
                alt="Sheikh-ul-Alam Memorial Degree College logo"
              />
            </div>
            <div className="col-md-8 col-12 text-center">
              <div className="row">
                <div className="col-md-4">
                  <p className="logobar-mail-box">
                    <FontAwesomeIcon icon={faEnvelope} size="3x" />
                    <span className="mailbox-text"> Mail Us today</span>
                  </p>
                  <p className="ltext">{details.email}</p>
                </div>
                <div className="col-md-4">
                  <p className="logobar-mail-box">
                    <FontAwesomeIcon icon={faPhone} size="3x" />
                    <span className="mailbox-text"> {details.phoneNo}</span>
                  </p>
                  <p className="ltext">Call us for more details</p>
                </div>
                <div className="col-md-4 col-12 text-center">
                  <img
                    className="govt_logo"
                    src={process.env.REACT_APP_APPURL + "images/govt.png"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoBar;
