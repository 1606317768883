import { Routes, Route } from "react-router-dom";
import CCollegeService from "../MainContent/CCollegeService/CCollegeService";
import About from "../MainContent/About/AboutUs/About";
import Department from "../MainContent/Department/Department";
import Home from "../MainContent/Home/Home";
import Iqac from "../MainContent/Iqac/Iqac";
import Nirf from "../MainContent/Nirf/Nirf";
import Gallery from "../MainContent/Gallery/Gallery";
import GalleryContent from "../MainContent/Gallery/GalleryContent";
import Admission from "../MainContent/Admission/Admission";
import "../MainContent/Admission/Admission.css";
import DepartmentDetail from "../MainContent/Department/DepartmentDetail";
import Downloads from "../MainContent/Downloads/Downloads";
import AllNews from "../MainContent/AllNewsNotifications/AllNews";
import AllNotifications from "../MainContent/AllNewsNotifications/AllNotifications";
import NewsItem from "../MainContent/AllNewsNotifications/NewsItem";
import PrincipalMessage from "../MainContent/About/PrincipalMessage/PrincipalMessage";
import VisionMission from "../MainContent/About/VisionMission/VisionMission";
import Staff from "../MainContent/Staff/Staff";
import PersonalityMessage from "../MainContent/About/PersonalityMessage/PersonalityMessage";
import AllEvents from "../MainContent/Home/LinksStudentSay/AllEvents";
import Library from "../MainContent/Department/Library";
import Error404 from "../utils/Error404";
import Services from "../MainContent/Services/Services";
import ContactUs from "../MainContent/Home/ContactUs";
import Organigram from "../MainContent/Organigram";
import Skill_Initiatives from "../MainContent/Skill_Initiatives";
import Alumini from "../MainContent/Alumini";
import Skill_InitiativeItem from "../MainContent/Skill_InitiativeItem";
import DSW from "../MainContent/Department/DSW";
import LibraryService from "../MainContent/Library/Library";
import Programs from "../MainContent/Programs/Programs";
import CommitteeList from "../MainContent/About/CommitteList/CommitteeList";
import EventDetail from "../MainContent/UpcomingEvents/EventDetail";
import Examination from "../MainContent/Examination/Examination";
import Activity from "../MainContent/Services/Activity";
import Activities from "../MainContent/Services/Activities";
import DynamicContent from "../MainContent/Dynamic/DynamicContent";
import Rti from "../MainContent/RTI/Rti";

const WiserRouter = ({ details = {} }) => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/about-us" element={<About />} />
      <Route exact path="/principal-message" element={<PrincipalMessage />} />
      <Route
        exact
        path="/personality-message"
        element={<PersonalityMessage />}
      />
      <Route exact path="/vision-mission" element={<VisionMission />} />
      <Route exact path="/services" element={<Services />} />
      <Route exact path="/activities" element={<Activities />} />
      <Route exact path="/services/:slug" element={<CCollegeService />} />
      <Route exact path="/activities/:slug" element={<Activity />} />
      <Route exact path="/college-departments" element={<Department />} />
      <Route
        exact
        path="/college-department-detail/:slug"
        element={<DepartmentDetail />}
      />
      <Route exact path="/all-downloads" element={<Downloads />} />
      <Route exact path="/all-news" element={<AllNews />} />
      <Route exact path="/all-news/:slug" element={<AllNews />} />
      <Route exact path="/details/:slug" element={<NewsItem />} />
      <Route exact path="/all-notifications" element={<AllNotifications />} />
      <Route
        exact
        path="/all-notifications/:slug"
        element={<AllNotifications />}
      />
      <Route exact path="/iqac-downloads" element={<Iqac />} />
      <Route exact path="/iqac-downloads/:slug" element={<Iqac />} />
      <Route exact path="/nirf" element={<Nirf />} />
      <Route exact path="/gallary" element={<Gallery />} />
      <Route exact path="/gallery/item/:slug" element={<GalleryContent />} />
      <Route exact path="/admission" element={<Admission />} />
      <Route exact path="/staff" element={<Staff />} />
      <Route exact path="/staff/:slug" element={<Staff />} />
      <Route exact path="/all-events" element={<AllEvents />} />
      <Route exact path="/all-events/:slug" element={<AllEvents />} />
      <Route exact path="/event/:slug" element={<EventDetail />} />
      <Route exact path="/library" element={<Library />} />
      <Route exact path="/dean-students-welfare" element={<DSW />} />
      <Route
        exact
        path="/contact-us"
        element={<ContactUs details={details} />}
      />
      <Route exact path="/organigram" element={<Organigram />} />
      <Route exact path="/skill-initiatives" element={<Skill_Initiatives />} />
      <Route exact path="/college-alumini" element={<Alumini />} />
      <Route exact path="/college-library" element={<LibraryService />} />
      <Route exact path="/college-programmes" element={<Programs />} />
      <Route exact path="/examination" element={<Examination />} />
      <Route exact path="/Committee-list" element={<CommitteeList />} />
      <Route exact path="/pages/:slug" element={<DynamicContent />} />
      <Route
        exact
        path="/skill-initiatives/:slug"
        element={<Skill_InitiativeItem />}
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default WiserRouter;
