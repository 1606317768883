import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab, Tabs } from "react-bootstrap";
import LineBottom from "../../utils/LineBottom";
const DepartmentSections = ({ data, name }) => {
  return (
    <>
      <LineBottom className="mt-4" span="More Information from" text={name} />
      <Tabs className="iqacPage-tabs department-section-tab-list">
        {data && data.length ? (
          data.map((section, index) => {
            return (
              <Tab
                key={index}
                className="iqacPage-tabItem department-section-tab"
                eventKey={index}
                title={section.name}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: section.content }}
                ></div>
              </Tab>
            );
          })
        ) : (
          <p className="no-info">
            <FontAwesomeIcon icon={faArrowCircleRight} /> No Information found
          </p>
        )}
      </Tabs>
    </>
  );
};

export default DepartmentSections;
