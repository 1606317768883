import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import "./TopBar.css";
import { Link } from "react-router-dom";

const TopBar = () => {
  return (
    <div className="top-bar">
      <div className="container top-bar-container">
        <div className="row g-0">
          <div className="col-md-6 col-12 top-bar-social-icons-container">
            <ul className="top_bar_list top_bar_social_icons">
              <li>
                <a href="">
                  <FontAwesomeIcon icon={faFacebookSquare} />
                </a>
              </li>
              <li>
                <a href="">
                  <FontAwesomeIcon icon={faTwitterSquare} />
                </a>
              </li>
              <li>
                <a href="">
                  <FontAwesomeIcon icon={faInstagramSquare} />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-12 top-bar-links-container">
            <ul className="top_bar_list top_bar_links">
              {/* <li>
                <a
                  target="_blank"
                  className="blink_me"
                  style={{ fontWeight: "bold" }}
                  href="https://system.spcollege.edu.in/notifications/Self%20Study%20Report%20for%203rd%20Cycle%20of%20Accreditation%20JKCOGN11726_638135362837183724.pdf"
                >
                  Self Study Report
                </a>
              </li> */}
              {/* <li>
                <span>|</span>
              </li> */}
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <span>|</span>
              </li>
              <li>
                <a href="mailto:info@samdc.edu.in">College Mail</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
