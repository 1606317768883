import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DynamicPageService from "../../../services/DynamicPageService";
import PublicService from "../../../services/PublicService";
import DynamicPage from "../../common/DynamicPage";
import ErrorBox from "../../utils/ErrorBox";
import PageLoader from "../../utils/PageLoader";
import "./Staff.css";

const Staff = () => {
  let { slug } = useParams();
  const [loaded, setLoaded] = useState(true);
  const [service, setService] = useState({});
  const [firstTime, setFirstTime] = useState(true);
  const [loading, setLoading] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [error, setError] = useState(false);
  const [options, setOptions] = useState([]);
  const [departmentId, setDepartmentId] = useState("");

  //dynamicpage/get-dynamic-page-byslug?slug=rti

  // const loadData = () => {
  //   setLoaded(false);
  //   PublicService.getStaffmembers(departmentId)
  //     .then((response) => {
  //       setStaffData(response.data);
  //       setLoaded(true);
  //       setFirstTime(false);
  //       window.scroll(0, 0);
  //     })
  //     .catch((error) => {
  //       setError(true);
  //       setFirstTime(false);
  //       setLoaded(true);
  //     });
  // };

  const loadStaffPage = () => {
    setLoaded(false);
    DynamicPageService.loadDynamicPageItem("staff")
      .then((response) => {
        setService(response.data);
        setLoaded(true);
      })
      .catch((error) => {
        setError(true);
        setLoaded(true);
      });
  };

  const loadAllDepartments = () => {
    setLoading(true);
    PublicService.loadDepartments()
      .then((response) => {
        if (response.data) {
          let options = [];
          response.data.forEach((element) => {
            options = options.concat(element.departments);
          });
          setOptions(options);
          if (slug) {
            setDepartmentId(options.find((x) => x.slug === slug).id);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError("Failed to load departments");
      });
  };
  const handleChange = (event) => {
    setDepartmentId(event.target.value);
  };
  useEffect(() => {
    loadAllDepartments();
  }, []);
  useEffect(() => {
    loadStaffPage();
  }, [departmentId]);
  return (
    <div style={{ minHeight: "200px" }} className="container my-5">
      {!loaded ? (
        <PageLoader />
      ) : error ? (
        <ErrorBox text="Failed to load data." />
      ) : service ? (
        <DynamicPage service={service} />
      ) : (
        ""
      )}
    </div>
  );
};

export default Staff;
