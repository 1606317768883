import { NavLink } from "react-router-dom";
import "./error404.css";

const Error404 = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-7 mt-4 mt-lg-0 d-flex justify-content-center align-items-center">
          <div className="">
            <div>
              <h1 className="font-weight-bold error-title"> 404 </h1>
              <p className="error-heading">Something went wrong!</p>
              <span className="error-text">
                The resource you're looking for doesn't exist.
              </span>

              <div>
                <NavLink className="btn-text btn btn-primary mt-3" to="/">
                  <span className="font-weight-bold link-text">Home</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="img-wrapper mt-5 mt-lg-3 mb-2 col-12 col-lg-5">
          <img src="/images/notfound.svg" alt="Not Found SVG"></img>
        </div>
      </div>
    </div>
  );
};

export default Error404;
