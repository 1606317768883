import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PublicService from "../../../services/PublicService";
import PageLoader from "../../utils/PageLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { dateFormat } from "../../../config/config";

const EventDetail = () => {
  let { slug } = useParams();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const loadEventDetail = () => {
    setLoader(true);
    PublicService.getPublicEvent(slug)
      .then((response) => {
        setData(response.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setError("Failed to load data");
      });
  };
  useEffect(() => {
    loadEventDetail();
  }, []);
  return !loader ? (
    <>
      {!data ? (
        <div className="row">
          <div
            style={{ padding: "30px" }}
            className="offset-4 col-md-4 text-center"
          >
            <Link to={"/all-events"}>
              <FontAwesomeIcon icon={faArrowLeft} /> Back to all events
            </Link>
            <div className="card">
              <div className="card-body">
                <h1>This page is under construction.</h1>
                <h3>Sorry for the inconveniences.</h3>
                <h3>We will be publishing this page soon. Stay connected.</h3>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ minHeight: "250px" }} className="container my-5">
          <Link to={"/all-events"}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back to all events
          </Link>
          <div className="mb-4 section-row">
            <h3 className="fs-5">
              <span>{data.title}</span>
            </h3>
            <div className="line-bottom"></div>
          </div>
          <div className="mb-3 eventInfo">
            <p className="location">
              {data.departmentName
                ? "Department of " + data.departmentName
                : "General"}
            </p>
            <p className="location">
              {data.location ? "Location : " + data.location : ""}
            </p>
            <p className="publish-date">
              Publish date : {dateFormat(data.publishDate)}
            </p>
          </div>

          {data.content ? (
            <>
              <div
                className="CCollegeService-ServiceData"
                dangerouslySetInnerHTML={{ __html: data.content }}
              />
              {data.personalityMessage ? (
                <div
                  className="CCollegeService-ServiceData"
                  dangerouslySetInnerHTML={{ __html: data.personalityMessage }}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            "No content found"
          )}
        </div>
      )}
    </>
  ) : (
    <PageLoader />
  );
};

export default EventDetail;
