import http from "./HttpService";

const loadLibraryData = async (name = "", author="", publication="") => {
  try {
    var response = await http.get(
      `public/get-books?title=${name}&author=${author}&publisher=${publication}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const LibraryService = {
    loadLibraryData,
};

export default LibraryService;
