import {
  faAward,
  faBookOpen,
  faCloudDownloadAlt,
  faHome,
  faImages,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const DepartmentLinks = () => {
  return (
    <div className="department-links">
      <div>
        <div className="row">
          <div className="col-md-2 one">
            <div className="dep-link-item">
              <a href="/">
                <p>
                  <FontAwesomeIcon icon={faHome} />
                </p>
                <p className="dep-link-text">Home</p>
              </a>
            </div>
          </div>

          <div className="col-md-2 two">
            <div className="dep-link-item">
              <Link to="/staff">
                <p>
                  <FontAwesomeIcon icon={faUsers} />
                </p>
                <p className="dep-link-text">Staff</p>
              </Link>
            </div>
          </div>
          <div className="col-md-2 three">
            <div className="dep-link-item">
              <Link to="/gallary">
                <p>
                  <FontAwesomeIcon icon={faImages} />
                </p>
                <p className="dep-link-text">Gallery</p>
              </Link>
            </div>
          </div>
          <div className="col-md-2 four">
            <div className="dep-link-item">
              <Link to="/all-downloads">
                <p>
                  <FontAwesomeIcon icon={faCloudDownloadAlt} />
                </p>
                <p className="dep-link-text">Downloads</p>
              </Link>
            </div>
          </div>
          <div className="col-md-2 five">
            <div className="dep-link-item">
              <Link to={"/iqac-downloads"}>
                <p>
                  <FontAwesomeIcon icon={faAward} />
                </p>
                <p className="dep-link-text">IQAC</p>
              </Link>
            </div>
          </div>
          <div className="col-md-2 six">
            <div style={{ border: "none" }} className="dep-link-item">
              <Link to={"/library"}>
                <p>
                  <FontAwesomeIcon icon={faBookOpen} />
                </p>
                <p className="dep-link-text">Library</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentLinks;
