import React, { useState, useEffect } from "react";
import PublicService from "../../../services/PublicService";
import LineBottom from "../../utils/LineBottom";
import ErrorBox from "../../utils/ErrorBox";
import PageLoader from "../../utils/PageLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faArrowCircleRight,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import Pagination from "../../utils/Pagination";

const AllNews = () => {
  let { slug } = useParams();
  const [allNews, setAllNews] = useState([]);
  const [loader, setLoader] = useState(false);
  const [departmentLoading, setDepartmentLoading] = useState(false);

  const [error, setError] = useState(null);
  const [options, setOptions] = useState([]);
  const [departmentId, setDepartmentId] = useState("");

  const handleDepartmentChange = (event) => {
    setDepartmentId(event.target.value);
  };
  const loadAllDepartments = () => {
    setDepartmentLoading(true);
    PublicService.loadDepartments()
      .then((response) => {
        if (response.data) {
          let options = [];
          response.data.forEach((element) => {
            options = options.concat(element.departments);
          });
          setOptions(options);
          if (slug) {
            setDepartmentId(options.find((x) => x.slug === slug).id);
          }
        }
        setDepartmentLoading(false);
      })
      .catch((error) => {
        setDepartmentLoading(false);
        setError("Failed to load departments");
      });
  };
  const loadNews = (pageNumber, pageSize) => {
    setLoader(true);
    PublicService.NewsNotifications(false, pageNumber, pageSize, departmentId)
      .then((response) => {
        setAllNews(response);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        setError("Failed to load data");
      });
  };
  useEffect(() => {
    loadAllDepartments();
  }, []);
  const handleOnPageChange = (pageNumber) => {
    loadNews(pageNumber, allNews.pageSize);
  };

  useEffect(() => {
    loadNews(1, 10);
    window.scroll(0, 0);
  }, [departmentId]);

  return (
    <div className="container my-5">
      <LineBottom span="All" text="News" />
      <div className="container my-5">
        <div className="row">
          <div className="col-md-4 form-group">
            <label>Filter by Department</label>
            <select
              value={departmentId}
              onChange={handleDepartmentChange}
              className="form-control"
            >
              <option value="">--Select--</option>
              {options &&
                options.map((option, index) => {
                  return <option value={option.id}>{option.name}</option>;
                })}
            </select>
          </div>
        </div>

        <ul className="nirfPage-list p-0">
          {!loader ? (
            error ? (
              <ErrorBox text={error} />
            ) : (
              <div className="all-news">
                <div className="news-content">
                  {allNews.data ? (
                    allNews.data.map((news) => (
                      <li key={news.id} className="nirfPage-list-item pl-0">
                        <div className="nirfPage-iconnirfWrapper d-flex">
                          <button className="nirfPage-iqacIcon ml-0">
                            <FontAwesomeIcon icon={faArrowCircleRight} />
                          </button>
                          <div className="nirfPage-link">
                            <Link
                              className="nirfPage-link-item"
                              to={"/details/" + news.slug}
                            >
                              <span className="nirfPage-nirftitle px-2">
                                {news.title}
                              </span>
                            </Link>
                          </div>
                        </div>
                        <div>
                          <span
                            style={{
                              paddingRight: "10px",
                              fontStyle: "italic",
                              fontSize: "12px",
                            }}
                          >
                            {news.departmentName}
                          </span>
                          <Link
                            to={"/details/" + news.slug}
                            className="nirfPage-download-btn"
                          >
                            <span className="nirfPage-downloadtext">View </span>
                            <FontAwesomeIcon icon={faEye} />
                          </Link>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="p-2">No Data found</div>
                  )}
                </div>
              </div>
            )
          ) : (
            <PageLoader />
          )}
        </ul>
      </div>
      {allNews.data && (
        <Pagination
          firstPage={allNews.firstPage}
          lastPage={allNews.lastPage}
          currentPage={allNews.pageNumber}
          handlePageChange={handleOnPageChange}
        />
      )}
    </div>
  );
};

export default AllNews;
