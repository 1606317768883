import {
  faCaretRight,
  faEnvelope,
  faFax,
  faGraduationCap,
  faLocationArrow,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.css";
const Footer = ({ details = {} }) => {
  return (
    <>
      <footer className="footer">
        <div className="container footer-container pt-5 pb-5">
          <div className="row">
            {/* logo and address information */}
            <div className="col-md-3 column1">
              <div className="white-logo">
                <img
                  src={process.env.REACT_APP_APPURL + "images/white-logo.png"}
                  alt=""
                />
              </div>
              <ul className="tg-infolist">
                <li>
                  <address>
                    <FontAwesomeIcon icon={faLocationArrow} /> {details.address}
                  </address>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faPhoneAlt} />
                    {details.phoneNo}
                  </span>
                </li>
                <li>
                  <span>
                    <FontAwesomeIcon icon={faFax} /> {details.fax}
                  </span>
                </li>
                <li>
                  <a href="mailto:principalsamdc5@gmail.com">
                    <span>
                      <FontAwesomeIcon icon={faEnvelope} /> {details.email}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            {/* important links */}
            <div className="col-md-5 offset-md-1 important-links">
              <h5>Important Links</h5>
              <div className="line-bottom-links"></div>
              <div className="row">
                <div className="col-6 col-sm-6 col-md-5">
                  <ul className="footer-links">
                    <li>
                      <a href="/">
                        <FontAwesomeIcon icon={faCaretRight} />
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="/about-us">
                        <FontAwesomeIcon icon={faCaretRight} />
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="/all-notifications">
                        {" "}
                        <FontAwesomeIcon icon={faCaretRight} />
                        All Notifications
                      </a>
                    </li>
                    <li>
                      <a href="/all-news">
                        <FontAwesomeIcon icon={faCaretRight} />
                        All News
                      </a>
                    </li>
                    <li>
                      <a href="/all-downloads">
                        <FontAwesomeIcon icon={faCaretRight} />
                        Downloads
                      </a>
                    </li>
                    <li>
                      <a href="/all-events">
                        <FontAwesomeIcon icon={faCaretRight} />
                        All Events
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-sm-6 col-md-5">
                  <ul className="footer-links">
                    <li>
                      <a href="/gallary">
                        <FontAwesomeIcon icon={faCaretRight} />
                        College Gallery
                      </a>
                    </li>
                    <li>
                      <a href="/principal-message">
                        <FontAwesomeIcon icon={faCaretRight} />
                        From Principal's Desk
                      </a>
                    </li>
                    <li>
                      <a href="/vision-mission">
                        <FontAwesomeIcon icon={faCaretRight} />
                        Vision and Mission
                      </a>
                    </li>
                    <li>
                      <a href="/iqac-downloads">
                        <FontAwesomeIcon icon={faCaretRight} />
                        IQAC
                      </a>
                    </li>
                    {/* <li>
                      <a href="/library">
                        <FontAwesomeIcon icon={faCaretRight} />
                        Library
                      </a>
                    </li> */}
                    <li>
                      <a href="/skill-initiatives">
                        <FontAwesomeIcon icon={faCaretRight} />
                        Skill Initiatives
                      </a>
                    </li>
                    <li>
                      <a href="/college-alumini">
                        <FontAwesomeIcon icon={faCaretRight} />
                        College Alumni
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Social Presence */}
            <div className="col-md-3 important-links">
              <h5>Our Social Presence</h5>
              <div className="line-bottom-links"></div>
              <div className="iframe-div">
                <iframe
                  style={{ width: "100%" }}
                  title="sam-college-facebook-page"
                  src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/samdcbudgam/%2F&tabs&height=200&small_header=true&hide_cover=false&show_facepile=true&appId"
                  height="154"
                  scrolling="no"
                  frameBorder="0"
                  className="facebook-page"
                  allow="encrypted-media"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="bottom-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>
                &copy; Copyright to{" "}
                <span style={{ fontWeight: "bold" }}>
                  Sheikh-ul-Alam Memorial Degree College
                </span>
                . All rights reserved
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-end">
                Maintained by{" "}
                <a style={{ fontWeight: "bold" }} href="#">
                  Sheikh-ul-Alam Memorial Degree College
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
