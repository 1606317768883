import {
  faArrowRight,
  faMapMarkerAlt,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorBox from "../../../utils/ErrorBox";
import LineBottom from "../../../utils/LineBottom";
import PageLoader from "../../../utils/PageLoader";
import { dateFormat, ifToday } from "../../../../config/config";
import { Link } from "react-router-dom";

const News = ({ loader, data, error, span, viewAllHref }) => {
  return (
    <div className="col-lg-4 row-section">
      <section id="About">
        <LineBottom className="mt-4" span={span || "Recent"} text="News" />
        <div>
          {loader ? (
            <PageLoader />
          ) : error ? (
            <ErrorBox text={"Failed to load message"} />
          ) : data.length === 0 ? (
            <ul className="download-list">
              <li className="no-upcoming-events">
                <p>
                  <FontAwesomeIcon icon={faArrowCircleRight} /> No news found
                </p>
              </li>
            </ul>
          ) : (
            <div className="newsSection-latest-news-list">
              {data.map((newsRecord) => (
                <div key={newsRecord.id} className="row mb-3">
                  <div
                    style={{ paddingRight: "0px" }}
                    className="col-md-3 col-3"
                  >
                    <img
                      className="rounded"
                      style={{
                        height: "65px",
                        width: "100%",
                        border: "4px solid #e2e0e0",
                      }}
                      alt=""
                      src={
                        newsRecord.newsImagePath
                          ? process.env.REACT_APP_HOST_URL +
                            "uploads/" +
                            newsRecord.newsImagePath
                          : process.env.REACT_APP_APPURL + "images/news.jpg"
                      }
                    />
                  </div>
                  <div className="col-md-9 col-9">
                    <p>
                      {ifToday(newsRecord.publishDate) ? (
                        <Link to={"/details/" + newsRecord.slug} data-new="new">
                          {newsRecord.title}
                        </Link>
                      ) : (
                        <Link to={"/details/" + newsRecord.slug}>
                          {newsRecord.title}
                        </Link>
                      )}
                    </p>
                    <p style={{ marginTop: "2px" }} className="date-of-news">
                      <span>{dateFormat(newsRecord.publishDate)}</span>
                      <span style={{ margin: "0 5px" }}>|</span>
                      <span>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                        <span>{newsRecord.departmentName || "General"}</span>
                      </span>
                    </p>
                    <Link to={"/details/" + newsRecord.slug}>
                      Read More <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          )}

          <p className="mb-2"></p>
          {data && data.length ? (
            <Link
              to={viewAllHref ? viewAllHref : "/all-news"}
              className="btn btn-primary btn-sm"
            >
              View All
            </Link>
          ) : (
            ""
          )}
        </div>
      </section>
    </div>
  );
};

export default News;
