import moment from "moment";

const dateFormat = (date) => {
  return moment(date).format("MMMM Do, YYYY");
};
const Day = (date) => {
  let dt = moment(date, "YYYY-MM-DD HH:mm:ss");
  return dt.format("ddd");
};
const DateNum = (date) => {
  let dt = moment(date, "YYYY-MM-DD HH:mm:ss");
  return dt.format("D");
};
const Month = (date) => {
  let dt = moment(date, "YYYY-MM-DD HH:mm:ss");
  return dt.format("MMM");
};
const ifToday = (current_date) => {
  if (
    moment().format("DD/MM/YYYY") === moment(current_date).format("DD/MM/YYYY")
  )
    return true;
  return false;
};

const getPageNumbers = (firstPage, lastPage, currentPage) => {
  if (currentPage === 0) return [];
  //Update values to 0 for those not to be shown
  let pageNumbers = [];
  let previousPage = currentPage - 1;
  let nextPage = currentPage + 1;
  for (let i = firstPage; i <= lastPage; i++) {
    if (
      i === firstPage ||
      i === lastPage ||
      i === currentPage ||
      i === nextPage ||
      i === previousPage
    )
      pageNumbers.push(i);
    else if (i < lastPage || i > firstPage) {
      pageNumbers.push(0);
    }
  }
  return pageNumbers.filter(
    (item, pos, arr) => pos === 0 || item !== arr[pos - 1]
  );
};

const fileExists = (url) => {
  fetch(url, { method: "GET" })
    .then((success) => {
      return true;
    })
    .catch((error) => {
      return false;
    });
};

export { dateFormat, ifToday, getPageNumbers, fileExists, Day, DateNum, Month };
