import LineBottom from "../../../utils/LineBottom";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faArrowRight,
  faBuilding,
  faCalendarAlt,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PageLoader from "../../../utils/PageLoader";
import ErrorBox from "../../../utils/ErrorBox";
import { dateFormat, ifToday } from "../../../../config/config";

const NewsBar = ({ loader, data, error, span, viewAllHref }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="section-wrapper news-section-wrapper">
      <div className="container mt-2 mb-5">
        <div className="row">
          <LineBottom className="mt-4" span={"Latest"} text="News" />
        </div>
        {loader ? (
          <PageLoader />
        ) : error ? (
          <ErrorBox text={"Failed to load message"} />
        ) : !data || data.length == 0 ? (
          <ul className="download-list">
            <li className="no-upcoming-events">
              <p>
                <FontAwesomeIcon icon={faArrowCircleRight} /> No news found
              </p>
            </li>
          </ul>
        ) : (
          <Slider {...settings}>
            {data &&
              data.map((newsRecord, index) => (
                <div key={index} className="news-bar-item">
                  <div className="news-bar-item-wrapper">
                    <img
                      src={
                        newsRecord.newsImagePath
                          ? process.env.REACT_APP_HOST_URL +
                            "uploads/" +
                            newsRecord.newsImagePath
                          : process.env.REACT_APP_APPURL + "images/news.jpg"
                      }
                      alt="news image"
                    />
                    <div className="news-text-wrapper">
                      <p className="news-date">
                        <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                        {dateFormat(newsRecord.publishDate)}
                      </p>
                      <p className="news-location">
                        <FontAwesomeIcon icon={faMapMarkedAlt} />{" "}
                        {newsRecord.departmentName || "General"}
                      </p>
                      <p className="news-title-text">
                        {ifToday(newsRecord.publishDate) ? (
                          <Link
                            to={"/details/" + newsRecord.slug}
                            data-new="new"
                          >
                            {newsRecord.title}
                          </Link>
                        ) : (
                          <Link to={"/details/" + newsRecord.slug}>
                            {newsRecord.title}
                          </Link>
                        )}
                      </p>
                      <p className="news-read-more">
                        <Link to={"/details/" + newsRecord.slug}>
                          Read More <FontAwesomeIcon icon={faArrowRight} />
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        )}
        {data && data.length ? (
          <Link
            style={{ float: "right" }}
            to={viewAllHref ? viewAllHref : "/all-news"}
            className="btn btn-primary btn-sm"
          >
            View All
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default NewsBar;
