import "../MainContent/CCollegeService/CCollegeService.css";
const DynamicPage = ({service}) => { 
  
      return (
        <div style={{ minHeight: "250px" }} className="container my-5">
          <div className="mb-4 section-row">
            <h3 className="fs-5">
              <span>{service.title}</span>
            </h3>
            <div className="line-bottom"></div>
          </div>

          {service.content ? (
            <div
              className="CCollegeService-ServiceData"
              dangerouslySetInnerHTML={{ __html: service.content }}
            />
          ) : (
            "No content found"
          )}
        </div>
      );  
};

export default DynamicPage;
