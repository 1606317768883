import {
  faArrowRight,
  faCalendarAlt,
  faCogs,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { dateFormat, ifToday } from "../../config/config";
import CollegeService from "../../services/CollegeService";
import ImageBanner from "../utils/ImageBanner";
import LineBottom from "../utils/LineBottom";
import PageLoader from "../utils/PageLoader";
const Skill_Initiatives = () => {
  const [skills, setSkills] = useState([]);
  const [skillPosts, setSkillPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [skill_InitiativeId, SetSkill_InitiativeId] = useState("");

  const loadSkills = () => {
    CollegeService.loadSkills()
      .then((response) => {
        setSkills(response.data);
        setError(false);
      })
      .catch((error) => {
        setError(true);
      });
  };

  const loadSkillPosts = (value = "") => {
    setLoading(true);
    CollegeService.loadSkillPosts(value)
      .then((response) => {
        setSkillPosts(response.data);
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };
  const handleChange = (event) => {
    SetSkill_InitiativeId(event.target.value);
    loadSkillPosts(event.target.value);
  };
  useEffect(() => {
    loadSkills();
    loadSkillPosts();
  }, []);
  return (
    <div>
      <ImageBanner
        title={"Skill Initiatives"}
        links={["Home", "Skill Initiatives"]}
        bannerImg={"/images/banner4.jpg"}
      />

      <div className="container mb-0">
        <div className="container mb-2 mt-md-5 mt-3">
          <div className="row">
            <div className="col-lg-12">
              <LineBottom
                className="mt-4"
                span={"Our"}
                text={"Skill Initiatives"}
              />
              <div className="container mb-4">
                <div className="row">
                  <div className="col-md-4 form-group">
                    <label>Filter by Initiative</label>
                    <select
                      value={skill_InitiativeId}
                      onChange={handleChange}
                      className="form-control"
                    >
                      <option value="">--Select--</option>
                      {skills &&
                        skills.map((option, index) => {
                          return (
                            <option value={option.id}>{option.name}</option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div>
                {loading ? (
                  <div className="mt-4">
                    <PageLoader />
                  </div>
                ) : skillPosts ? (
                  <div className="row">
                    {skillPosts.map((item, index) => {
                      return (
                        <div key={index} className="news-bar-item col-md-4">
                          <div
                            style={{ margin: "10px 0" }}
                            className="news-bar-item-wrapper"
                          >
                            <img
                              src={
                                item.thumbnail
                                  ? process.env.REACT_APP_HOST_URL +
                                    "uploads/" +
                                    item.thumbnail
                                  : process.env.REACT_APP_APPURL +
                                    "images/news.jpg"
                              }
                              alt="news image"
                            />
                            <div
                              style={{ minHeight: "162px" }}
                              className="news-text-wrapper"
                            >
                              <p className="news-date">
                                <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                                {dateFormat(item.createdDate)}
                              </p>
                              <p className="news-location">
                                <FontAwesomeIcon icon={faCogs} />{" "}
                                {item.skillCategory}
                              </p>
                              <p
                                style={{ minHeight: "0px" }}
                                className="news-title-text"
                              >
                                {ifToday(item.createdDate) ? (
                                  <Link
                                    to={"/skill-initiatives/" + item.slug}
                                    data-new="new"
                                  >
                                    {item.title}
                                  </Link>
                                ) : (
                                  <Link to={"/skill-initiatives/" + item.slug}>
                                    {item.title}
                                  </Link>
                                )}
                              </p>
                              <p className="news-read-more">
                                <Link to={"/skill-initiatives/" + item.slug}>
                                  Read More{" "}
                                  <FontAwesomeIcon icon={faArrowRight} />
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <h5 className="badge badge-warning">No Data found</h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skill_Initiatives;
