import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../MainContent/AllNewsNotifications/NewsItem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CollegeService from "../../services/CollegeService";
import { dateFormat } from "../../config/config";
import ErrorBox from "../utils/ErrorBox";
import PageLoader from "../utils/PageLoader";

const Skill_InitiativeItem = () => {
  let { slug } = useParams();
  const [skill, setSkill] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const loadNews = () => {
    setLoader(true);
    CollegeService.loadSkillPostItem(slug)
      .then((response) => {
        setSkill(response.data);
        setLoader("Failed to load data");
        setLoader(false);
      })
      .catch((error) => {
        setError(true);
        setLoader(false);
      });
  };

  useEffect(() => {
    loadNews();
  }, [slug]);

  return !loader ? (
    error ? (
      <ErrorBox text={error} />
    ) : (
      <div className="container my-4">
        <div className="news-item-wrapper">
          <Link to={"/skill-initiatives"}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </Link>
          <p className="location">{skill.skillCategory}</p>

          <h2 className="news-title">{skill.title}</h2>
          <p className="publish-date">{dateFormat(skill.createdDate)}</p>
          <div dangerouslySetInnerHTML={{ __html: skill.content }}></div>
        </div>
      </div>
    )
  ) : (
    <div className="my-4">
      <PageLoader />
    </div>
  );
};

export default Skill_InitiativeItem;
