import {
  faIntercom,
  faInternetExplorer,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CollegeService from "../../../services/CollegeService";
import ImageBanner from "../../utils/ImageBanner";
import LineBottom from "../../utils/LineBottom";
import PageLoader from "../../utils/PageLoader";

const Services = () => {
  const [services, setServices] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadCollegeServices = () => {
    setLoading(true);
    CollegeService.loadServices()
      .then((response) => {
        setServices(response.data);
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }; 

  useEffect(() => {
    loadCollegeServices();    
  }, []);
  return loading ? (
    <div style={{ height: "300px" }}>
      <div className="mt-3">
        <PageLoader />
      </div>
    </div>
  ) : (
    <div>
      <ImageBanner
        title={"Services"}
        links={["Home", "Services"]}
        bannerImg={"/images/banner4.jpg"}
      />
      <div className="container mb-0">
        <div className="container mb-2 mt-md-5 mt-3">
          {/* Facilities */}
          <div className="row">
            <div className="col-lg-12">
              <LineBottom className="mt-4" span={"Our"} text={"Facilities"} />
              <div>
                {loading ? (
                  <div className="mt-4">
                    <PageLoader />
                  </div>
                ) : services ? (
                  <div className="service-row row">
                    {services.map((item, index) => {
                      return (
                        <div key={index} className="service-col col-md-2">
                          <Link to={"/services/" + item.slug}>
                            <div className="service-wrapper">
                              <p className="service-icon">
                                <i
                                  className={`fa ${
                                    item.icon ? item.icon : "fa-file"
                                  }`}
                                ></i>
                              </p>
                              <p className="service-title">{item.name}</p>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <h3 className="badge badge-warning">No Data found</h3>
                )}
              </div>
            </div>
          </div>       
        </div>
      </div>
    </div>
  );
};

export default Services;
