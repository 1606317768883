import React, { useState, useEffect } from 'react';
import PublicService from '../../../../services/PublicService';
import PageLoader from '../../../utils/PageLoader';
import ErrorBox from '../../../utils/ErrorBox';
import { Breadcrumb, Image } from 'react-bootstrap';
import LineBottom from '../../../utils/LineBottom';

const PersonalityMessage = () => {

  const [pmLoader, setPmLoader] = useState(false);
  const [pmMessage, setPmMessage] = useState({});
  const [pmError, setPmError] = useState(null);

  const loadPersonalityMessage = () => {
    setPmLoader(true);
    PublicService.getPersonalityMessage()
      .then(response => {
        setPmMessage(response.data);
        setPmLoader(false);
      })
      .catch(error => {
        setPmError("Failed to load data");
        setPmLoader(false);
      })
  }

  useEffect(() => {
    window.scroll(0, 0);
    loadPersonalityMessage();
  }, []);

  return (
    pmLoader ? <div className='my-4'><PageLoader /></div> : pmError ? <ErrorBox /> :
      <div className='pm'>
        <div className="banner_image">
          <Image src="/images/banner.jpg" />
          <div className="banner-description">
            <h4>{pmMessage.name + "'s"} Message</h4>
            <div className="mt-1 banner-breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>{pmMessage.name + "'s"} Message</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row d-flex my-4'>
            <div className='col-md-4 col-lg-3 col-sm-4'>
              <img
                src={
                  pmMessage.imagePath ?
                    process.env.REACT_APP_HOST_URL +
                    "uploads/" +
                    pmMessage.imagePath :
                    "/images/corrupted.jpg"
                }
                className="img-fullwidth"
                alt={pmMessage.name + "'s Message"}
                title="https://beta.bgsbu.ac.in/storage/photos/3/Administrative/LG7.jpg"
              />
            </div>
            <div className='col-md-8 col-lg-9 col-sm-8 mt-3 mt-md-0'>
              <div className='heading'>
                <LineBottom span={pmMessage.name + "'s"} text="Message" />
              </div>
              <div dangerouslySetInnerHTML={{ __html: pmMessage.message }}></div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default PersonalityMessage;