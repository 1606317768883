import React, { useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputTextField from "../../common/InputTextField";
import Joi from "joi-browser";
import LibraryService from "../../../services/LibraryService";
import TableData from "../../common/TableData";
import ErrorBox from "../../utils/ErrorBox";
import PageLoader from "../../utils/PageLoader";

const Library = () => {
  const defaultFormData = {
    name: "",
    author: "",
    publication: "",
  };
  const [librarySearch, setLibrarySearch] = useState(defaultFormData);
  const [errors, setErrors] = useState({});
  const [ValErrors, setValErrors] = useState({});
  const [loaded, setLoaded] = useState(true);
  const [libraryData, setLibraryData] = useState([]);
  const [error, setError] = useState(false);
  const loadData = (e) => {
    e.preventDefault();
    setValErrors(false);
    if (
      librarySearch.name == "" &&
      librarySearch.author == "" &&
      librarySearch.publication == ""
    ) {
      setValErrors(true);
    } else {
      setLoaded(false);
      LibraryService.loadLibraryData(
        librarySearch.name,
        librarySearch.author,
        librarySearch.publication
      )
        .then((response) => {
          setLibraryData(response.data);
          setLoaded(true);
        })
        .catch((error) => {
          setError(true);
          setLoaded(true);
        });
    }
  };
  const handleOnChange = (event) => {
    const deptUser = { ...librarySearch };
    deptUser[event.target.name] = event.target.value;
    setLibrarySearch(deptUser);
  };
  return (
    <div className="container my-5">
      <div className="my- section-row">
        <h3 className="fs-5">
          <span>College</span> Library
        </h3>
        <div className="line-bottom"></div>
      </div>
      <form onSubmit={loadData}>
        <div className="row mb-3">
          {ValErrors == true ? (
            <span className="text-danger">
              {" "}
              *Enter at least one search criteria
            </span>
          ) : (
            <span></span>
          )}
          <div className="col-md-4">
            <InputTextField
              name="name"
              type="text"
              label="Book Name"
              placeholder={"Name"}
              handleInputChange={handleOnChange}
              value={librarySearch.name}
              error={errors.name}
            />
          </div>
          <div className="col-md-auto text-center text-muted pt-4 mt-1 ">
            OR
          </div>
          <div className="col-md-3">
            <InputTextField
              name="author"
              type="text"
              label="Book Author"
              placeholder={"Author"}
              handleInputChange={handleOnChange}
              value={librarySearch.author}
              error={errors.author}
            />
          </div>
          <div className="col-md-auto text-center text-muted pt-4 mt-1">OR</div>
          <div className="col-md-3">
            <InputTextField
              name="publication"
              type="text"
              label="Publication"
              placeholder={"Publication"}
              handleInputChange={handleOnChange}
              value={librarySearch.publication}
              error={errors.publication}
            />
          </div>
          <div className="col-md-auto" style={{ marginTop: "22px" }}>
            {loaded == true ? (
              <button type="submit" className="btn btn-primary btn-sm">
                <FontAwesomeIcon icon={faSearch} />
              </button>
            ) : (
              <button type="submit" className="btn btn-primary btn-sm" disabled>
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />
              </button>
            )}
          </div>
        </div>
      </form>

      {loaded == false ? (
        <PageLoader />
      ) : error ? (
        <ErrorBox text="Failed to load data." />
      ) : (
        <TableData
          listText={[
            "Title",
            "Author",
            "Publisher",
            "Class Number",
            "Location",
          ]}
          listkeys={["title", "author", "publisher", "callNumber", "location"]}
          dataList={libraryData}
        />
      )}
    </div>
  );
};

export default Library;
