import http from "./HttpService";

const topNewsNotifications = async (isNotice, slug) => {
  try {
    const response = await http.get(
      !slug
        ? `public/top-news-notifications?IsNotice=${isNotice}`
        : `public/top-news-notifications?IsNotice=${isNotice}&slug=${slug}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPublicEvents = async (departmentId = "") => {
  try {
    const response = await http.get(
      `upcomingevents/get-public-events?departmentId=${departmentId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPublicEvent = async (slug) => {
  try {
    const response = await http.get(`upcomingevents/get-event?slug=${slug}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const topEvents = async (slug = "") => {
  try {
    const response = await http.get(`public/top-events?slug=${slug}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const topDownloads = async (slug) => {
  try {
    const response = await http.get(`public/top-downloads?slug=${slug}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getInstituteDetails = async () => {
  try {
    const response = await http.get(`public/get-institute-details`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const topSliderImages = async () => {
  try {
    const response = await http.get(`public/top-slider-images`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const NewsNotifications = async (
  isNotice,
  pageNumber,
  pageSize,
  departmentId = ""
) => {
  try {
    const response = await http.get(
      `get-news-notifications?IsNotice=${isNotice}&departmentId=${departmentId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const NewsNotificationBySlug = async (isNotice, slug) => {
  try {
    const response = await http.get(
      `get-news-notification-byslug?IsNotice=${isNotice}&slug=${slug}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const loadDownloads = async (categoryName, pageNumber, pageSize) => {
  try {
    const response = await http.get(
      `public/downloads?categoryName=${categoryName}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const loadDepartments = async () => {
  try {
    const response = await http.get(`public/departments`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const loadStudentSay = async () => {
  try {
    const response = await http.get(`public/fetch-students-say`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const loadDepartmentDetails = async (slug) => {
  try {
    const response = await http.get(
      `public/get-department-detail?slug=${slug}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getStaffmembers = async (departmentId) => {
  try {
    const response = await http.get(
      `staff-members/get-staff-members?departmentId=${departmentId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPersonalityMessage = async () => {
  try {
    const response = await http.get(`public/personality-message`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const loadLibraryServices = async () => {
  try {
    const response = await http.get("public/library-services");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const PublicService = {
  topNewsNotifications,
  topEvents,
  getInstituteDetails,
  topSliderImages,
  NewsNotifications,
  NewsNotificationBySlug,
  loadDownloads,
  loadDepartments,
  loadDepartmentDetails,
  topDownloads,
  getStaffmembers,
  getPersonalityMessage,
  loadStudentSay,
  getPublicEvents,
  getPublicEvent,
  loadLibraryServices,
};

export default PublicService;
